<ng-template #empty>
  <ul class="list-group">
    <li class="list-group-item list-group-item-info">Es ist noch keine <b>Profil-M Kompetenz</b> hinzugefügt
      worden.</li>
  </ul>
</ng-template>
<div class="card set-view-card">
  <div class="card-body">
    <h5>
      <div class="row">
        <div class="col">
          <i class="ri-list-check-3"></i>
          <span *ngIf="!isPrm()">Vordefinierte Kundenkompetenz</span>
          <span *ngIf="isPrm()">Vordefinierte Profil M-Kompetenz</span>
        </div>
      </div>
    </h5>
    <span *ngIf="isPrm()">
      <div class="alert alert-warning">
        {{selectedCustomerdimension.name}}
      </div>
    </span>
    <ng-container *ngIf="!isPrm()">
      <div class="row">
        <div class="col">
          <label style="color: #00000000; font-size: 12px;">.</label>
          <div class="row">
            <div class="col">
              <form ngForm>
                <div class="form-group">
                  <!-- NAME INPUT -->
                  <label for="name">Name</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="name"
                    placeholder="Name"
                    name="name"
                    [(ngModel)]="$any(selectedCustomerdimension).name"
                  >

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="name">Profil-M Kompetenz hinzugefügt</label>
            <ng-select
              [items]="alldimensions"
              #dimensionSelect
              (change)="onAddDimension($event);"
              bindLabel="name"
              bindValue="name"
              [groupBy]="groupByFn"
              [groupValue]="groupValueFn"
              [searchFn]="customSearchFn"
              class="form-control-sm  form-control"
            >
              <ng-template
                let-item="item"
                ng-label-tmp
              >
                <div class="row">
                  <div class="col">
                    <span>{{ item.name}}</span>
                  </div>
                </div>
              </ng-template>
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-index="index"
              >
                <span style="color:#000 !important;font-size: larger; font-weight: bold"> {{ item.name }}</span>
              </ng-template>
              <ng-template
                let-index="index"
                let-item="item"
                let-search="searchTerm"
                ng-option-tmp
              >
                <div class="row">
                  <div class="col-12">
                    <span><b>{{ item.name}}</b></span>
                  </div>
                  <div class="col-12">
                    <span>{{ item.parentDimension.name }}</span>
                  </div>
                </div>
              </ng-template>
            </ng-select>
            <hr>
            <div
              class="list-group"
              *ngIf="($any(selectedCustomerdimension).dimensions.length  > 0 ); else empty"
            >
              <ng-container *ngFor="let dimension of $any(selectedCustomerdimension).dimensions; index as i ">
                <span class="list-group-item">
                  {{dimension.name}}
                  <i
                    class="btn ri-close-circle-fill text-danger"
                    (click)="removeDimension(dimension, i)"
                  ></i>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>