import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UseradminService } from '../useradmin.service';

@Injectable()
export class SelectedUserResolver  {

  constructor(private service: UseradminService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const selectedUser = await this.service.getUserbyId(route.params.id);
    return selectedUser;
  }
}

