import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { User } from './auth/user.model';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ExerciseSetService } from './exercise-sets/exercise-set.service';
import { LayoutService } from './shared/layout.service';
import { LoadingService } from './shared/loading.service';
import { ProfilmService } from './shared/profilm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private userSubscription: Subscription;
  isAuthenticated = true;
  user: User;
  isAdmin: boolean;

  @HostListener('window:beforeunload', ['$event'])
  showMessage($event) {
    // Neue Lösung -> evtl trotzdem noch nicht perfekt
    if (this.exerciseSetService.getIsDirtyFromAllSets() === true) {
      $event.returnValue = 'Your data will be lost!';
    }
  }

  constructor(
    public router: Router,
    private authService: AuthService,
    private layoutServiceObJ: LayoutService,
    public exerciseSetService: ExerciseSetService,
    private modalService: BsModalService,
    public loadingService: LoadingService,
    private profilMservice: ProfilmService
  ) { }

  get layoutService(): LayoutService {
    return this.layoutServiceObJ;
  }

  async ngOnInit(): Promise<void> {
    this.userSubscription = this.authService.user.subscribe(async user => {
      this.isAuthenticated = !!user;
      if (this.isAuthenticated) {
        this.user = user;
        this.isAdmin = user.isadmin;
        await this.profilMservice.getProfilmData();
      }
    });

  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  onLogout(): void {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  public openLogoutModal() {
    const modalLogoutRef = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog modal-centered',
      keyboard: false,
      initialState: {
        modalMsg: 'Möchtest du dich wirklich <u>ausloggen</u>?<br> Dabei gehen ungespeicherte Änderungen verloren.'
      }
    });
    modalLogoutRef.content?.hasConfirmed.subscribe((result) => {
      if (result) {
        this.onLogout();
        modalLogoutRef.hide();
      }
    });
  }

  public checkDirtyAndManageLogout() {
    if (this.exerciseSetService.getIsDirtyFromAllSets()) {
      this.openLogoutModal()
    } else {
      this.onLogout()
    }
  }
}
