import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { User } from './user.model';

interface AuthResponseData {
  name: string;
  email: string;
  isadmin: boolean;
  password: string;
  _id: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class UseradminService {
  user = new BehaviorSubject<User>(null);
  private users: User[] = [];
  private userIdMap: Map<string, User> = new Map<string, User>();

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user == null) {
        this.logout();
      }
    });
  }

  public logout(): void {
    this.users = [];
    this.userIdMap = new Map<string, User>();
    this.user.next(null);
  }


  async addNewUser(name: string, email: string, isadmin: boolean): Promise<any> {
    if (isadmin !== true) {
      isadmin = false;
    }
    try {
      const userResponse = await this.http.post<AuthResponseData>(
        '/api/user',
        { name: name, email: email, isadmin: isadmin }
      ).toPromise();
      const user = new User(
        userResponse._id,
        userResponse.email,
        userResponse.name,
        userResponse.isadmin
      );
      this.users.push(user);
      return userResponse;
    } catch (e) {
      this.handleError(e);
    }
  }

  changeUser(name, email, isadmin, id) {
    return this.http
      .put<AuthResponseData>(
        '/api/user',
        { name: name, email: email, isadmin: isadmin, _id: id }
      )
      .pipe(catchError(this.handleError));
  }

  deleteUser(id) {
    return this.http
      .delete<AuthResponseData>(
        '/api/user',
        { params: new HttpParams().set('_id', id) });
  }

  public async getUsers() {
    if (this.users.length === 0) {
      await this.fetchUsers();
    }
    return this.users;
  }

  public idToName(id: string): string {
    if (!this.userIdMap.has(id)) {
      return id;
    }
    return this.userIdMap.get(id).name;
  }

  public async getUserbyId(id: string) {
    if (this.users.length === 0) {
      await this.fetchUsers();
    }
    const index = this.users.findIndex(user => user._id === id);
    if (index > -1) {
      return this.users[index];
    } else {
      return null;
    }
  }

  private async fetchUsers() {
    return this.http.get<User[]>('/api/user')
      .pipe(
        tap(users => {
          this.users = users;
          for (const u of users) {
            this.userIdMap.set(u._id, u);
          }
        })).toPromise();
  }

  requestPWRecovery(email: string) {
    return this.http
      .get<AuthResponseData>('/api/auth/requestNewPass', { params: new HttpParams().set('email', email) })
      .pipe(catchError(this.handleError));
  }

  private handleError(errResp: HttpErrorResponse) {
    const errMsg = 'Unknown Error (' + errResp.status + ')';
    return throwError({ msg: errMsg, nativeRespnse: errResp });
  }
}
