import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {
  private httpError = new Subject<HttpErrorResponse>();

  public setError(errorResponse: HttpErrorResponse) {
    this.httpError.next(errorResponse);
  }

  public getError(): Subject<HttpErrorResponse> {
    return this.httpError;
  }
}
