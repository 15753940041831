import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RecoverpwService } from './recoverpw.service';

@Component({
  selector: 'app-recoverpw',
  templateUrl: './recoverpw.component.html',
  styleUrls: ['./recoverpw.component.scss']
})
export class RecoverpwComponent implements OnInit {
  errorMessage: string = null;
  code: string;
  validCode: boolean;

  constructor(private route: ActivatedRoute, private recoverService: RecoverpwService) { }

  ngOnInit(): void {
    this.code = this.route.snapshot.params.key;
    this.recoverService.checkCode(this.code).subscribe(r => {
      this.validCode = r;
    });
  }
  onSubmit(form: NgForm) {
    this.errorMessage = null;
    if (!form.valid) {
      return;
    }
    this.recoverService.setPassword(this.code, form.value.password).subscribe(resData => {
    },
      errorResponse => {
        this.errorMessage = errorResponse.nativeRespnse.error.message;
      }
    );
    form.reset();
  }
}
