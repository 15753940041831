import { ProfilmExerciseType } from '../../shared/profilm-exercise-type.model';

export class Anchor {
  public text: string;
  public type?: string;

  constructor(a: any) {
    this.text = '';
    if (!!a.text) {
      this.text = a.text;
    }
  }
}

export class Question {
  public text: string;
  public type?: string;

  constructor(q: any) {
    this.text = '';
    if (!!q.text) {
      this.text = q.text;
    }
    if (!!q.type) {
      this.type = q.type;
    }
  }
}

export class Dimension {
  public name: string;
  public anchor: Anchor[];
  public questions: Question[];
  public profilmSubDimIdent: string;
  public inCustomerDimensions: string[];

  constructor(d: Dimension) {
    this.name = d.name;
    this.questions = [];
    this.anchor = [];
    this.inCustomerDimensions = [];
  }

  public static createFromProfilmSubDimension(psd: any): Dimension {
    const result: Dimension = new Dimension(psd);
    result.profilmSubDimIdent = psd.name;
    return result;
  }
}

export class Exercise {
  public types: ProfilmExerciseType[] = [];
  public dimensions: Dimension[];
  public isCustomerDimension: boolean;
  public customerDimensions: string[];
  public introQuestions: Question[];

  constructor(public name: string) {
    this.isCustomerDimension = false;
    this.customerDimensions = [];
    this.dimensions = [];
    this.introQuestions = [];
  }

  public hasType(type: ProfilmExerciseType): boolean {
    return this.types.some(e => e.de === type.name || e.en === type.name);
  }

  public toggleType(type: ProfilmExerciseType): void {
    if (!this.hasType(type)) {
      this.types.push(type);
    } else {
      const idx: number = this.types.findIndex(e => e.de === type.name || e.en === type.name);
      this.types.splice(idx, 1);
    }
  }
}