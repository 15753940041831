import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { LayoutService } from 'src/app/shared/layout.service';
import { ProfilmSubDimension } from 'src/app/shared/profilm-sub-dimension.model';
import { ProfilmService } from 'src/app/shared/profilm.service';
import { ExerciseSet } from '../exercise-set.model';
import { AcrossCompetencie } from './across-competencie.model';

@Component({
  selector: 'app-across-competencies',
  templateUrl: './across-competencies.component.html',
  styleUrls: ['./across-competencies.component.scss']
})
export class AcrossCompetenciesComponent implements OnInit {
  public allsubdimenstions: ProfilmSubDimension[];
  @ViewChild('dimensionSelect') public dimensionSelect: NgSelectComponent;
  @Input() public acrossCompetencies: any[] = [];
  @Input() exerciseset: ExerciseSet;
  constructor(public profilMservice: ProfilmService, public layoutService: LayoutService) {
    this.allsubdimenstions = profilMservice.getAllSubDimensions();
  }

  ngOnInit(): void {
    this.filterSelectableSubDimensions();
  }
  groupByFn = (item) => item.parentDimension.name;
  groupValueFn = (_: string, children: any[]) => ({ name: children[0].parentDimension.name });
  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.parentDimension.name.toLowerCase().indexOf(term) > -1;
  }

  addCustomerCompetencie(e): void {
    const newval = e.target.value;
    let found = false;
    this.acrossCompetencies.forEach((item) => {
      if (item.name === newval) {
        found = true;
      }
    });
    if (!found) {
      this.acrossCompetencies.push(new AcrossCompetencie(e.target.value));
      e.target.value = '';
    }

  }

  onAddCompetencies(e) {
    if (!e) {
      return;
    }
    const subdim: ProfilmSubDimension = e;
    this.acrossCompetencies.push(new ProfilmSubDimension(subdim.de, subdim.en, subdim.anchor, subdim.questions));
    this.filterSelectableSubDimensions();
    this.dimensionSelect.clearModel();
  }
  public filterSelectableSubDimensions() {
    this.allsubdimenstions = this.allsubdimenstions.filter((subd: ProfilmSubDimension) => !this.acrossCompetencies.some(a => a.name === subd.name));
  }
}
