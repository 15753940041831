<div class="modal-body">
  <div class="d-flex justify-content-center">
    <i class="ri-error-warning-fill text-danger ri-5x"></i>
  </div>
  <div class="col-12">
    <div class="text-center pt-4">
      <h2>Bist du dir sicher?</h2>
      <span [innerHTML]="this.modalMsg"></span>
    </div>
  </div>
  <div class="col-12 text-center pt-4">
    <button
      type="button"
      class="btn btn-secondary me-2"
      (click)="this.bsModalRef.hide()"
    >Abbrechen</button>
    <button
      type="button"
      class="btn btn-danger"
      data-bs-dismiss="modal"
      (click)="this.confirm()"
    >
      <i class="ri-delete-bin-line"></i>
      Löschen
    </button>
  </div>
</div>