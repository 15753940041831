<h5>
  <i class="ri-list-check-3"></i>
  Übungen ({{exercises.length }})
</h5>

<ng-template #empty>
  <ul class="list-group">
    <li class="list-group-item list-group-item-info">Es sind noch keine <b>Übungen</b> hinzugefügt worden.</li>
  </ul>
</ng-template>

<div
  *ngIf="this.exercises.length > 0; else empty"
  cdkDropList
  class="list-group list-group-flush"
  (cdkDropListDropped)="reArrangeExercises($event)"
>
  <div
    class="list-group-item list-group-item-action"
    *ngFor="let exercise of exercises; let i = index;"
    [routerLink]="['/', 'selectedset', this.exerciseset._id, i]"
    routerLinkActive="selected-mark"
    cdkDrag
  >
    <app-exercise-list-item
      [exercise]="exercise"
      style="cursor: pointer;"
    >
      <i
        cdkDragHandle
        class="ri-menu-line"
        style="cursor: ns-resize;"
      ></i>
    </app-exercise-list-item>
  </div>
</div>

<div class="row pt-4">
  <div class="col">
    <form
      class=""
      (ngSubmit)="onAddExercise(f)"
      #f="ngForm"
    >
      <input
        type="text"
        class="form-control mb-2 me-sm-2  form-control-sm b"
        placeholder="Neue Übung"
        name="name"
        ngModel
        #newExerciseNameInput
      >
      <button
        class="btn btn-primary mb-2 btn-sm"
        type="submit"
      >
        <i class="ri-add-line"></i>
        Hinzufügen
      </button>
    </form>
  </div>
</div>