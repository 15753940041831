import { Component, Input } from '@angular/core';
import { ProfilmDimension } from '../shared/profilm-dimension.model';
import { ProfilmExerciseType } from '../shared/profilm-exercise-type.model';


@Component({
  selector: 'app-prm-catalog',
  templateUrl: './prm-catalog.component.html',
  styleUrls: ['./prm-catalog.component.scss']
})
export class PrmCatalogComponent {
  @Input() coredimension: ProfilmDimension[];
  @Input() exerciseTypes: ProfilmExerciseType[];
  public collapsed: any = {};
}