import { Component, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '../../shared/layout.service';
import { ExerciseSet } from '../exercise-set.model';
import { ExerciseSetService } from '../exercise-set.service';
import { ExerciseSetsSidebarService } from './exercise-sets-sidebar.service';

@Component({
  selector: 'app-exercise-sets-sidebar-content',
  templateUrl: './exercise-sets-sidebar-content.component.html',
  styleUrls: ['./exercise-sets-sidebar-content.component.scss']
})
export class ExerciseSetsSidebarContentComponent {
  public exerciseSets: ExerciseSet[];
  @Output() public selectedSet: EventEmitter<any> = new EventEmitter();

  constructor(activatedRoute: ActivatedRoute, public exerciseSetService: ExerciseSetService, public layout: LayoutService, public router: Router, public sidebarService: ExerciseSetsSidebarService) {
    activatedRoute.data.subscribe(value => {
      this.exerciseSets = value.exerciseSets;
    });
  }

  async onAddExerciseSet(form: NgForm) {
    if (form.valid) {
      const newset = new ExerciseSet(form.value.name);
      form.resetForm();
      const newSet = await this.exerciseSetService.addExerciseSet(newset);
      this.router.navigate(['/selectedset/' + newSet._id]);
      this.layout.exercisePanelOpen = false;
      this.selectedSet.emit();
    }
  }

  public toggleCollapsed(folder: string) {
    this.sidebarService.toggleFolder(folder);
  }

  public isCollapsed(folder: string) {
    return !this.sidebarService.openedFolders.includes(folder);
  }
}
