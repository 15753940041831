import { Component } from '@angular/core';
import { LayoutService } from '../shared/layout.service';

@Component({
  selector: 'app-exercise-sets',
  templateUrl: './exercise-sets-area.component.html',
  styleUrls: ['./exercise-sets-area.component.scss']
})
export class ExerciseSetsAreaComponent {
  public sidebarVisible = false;
  constructor(private layout: LayoutService) { }
}