import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RecoverpwService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  setPassword(code, password) {
    return this.http
      .get<any>('/api/auth/recoverPass', { params: new HttpParams().set('code', code).set('password', password) })
      .pipe(catchError(this.handleError), tap(respData => {
        this.authService.autoLogin()
      }));
  }

  checkCode(code) {
    return this.http.get<any>('/api/auth/checkRecoverCode', { params: new HttpParams().set('code', code) })
      .pipe(catchError(this.handleError));
  }

  private handleError(errResp: HttpErrorResponse) {
    const errMsg = 'Unknown Error (' + errResp.status + ')';
    return throwError({ msg: errMsg, nativeRespnse: errResp });
  }
}