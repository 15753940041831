import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ExerciseSet } from '../exercise-set.model';
import { Exercise } from '../exercise/exercise.model';

@Component({
  selector: 'app-exercise-list',
  templateUrl: './exercise-list.component.html',
  styleUrls: ['./exercise-list.component.scss']
})
export class ExerciseListComponent {
  @Input() exercises: Exercise[];
  @Input() exerciseset: ExerciseSet;
  @ViewChild('newExerciseNameInput') newExerciseNameInput: HTMLInputElement;

  constructor(private router: Router) {
  }

  public onAddExercise(form: NgForm) {
    this.exercises.push(new Exercise(form.value.name));
    form.resetForm();
  }

  public reArrangeExercises(event: any) {
    moveItemInArray(this.exerciseset.exercises, event.previousIndex, event.currentIndex);
  }

  openExerciseDetails(i: number) {
    this.router.navigate(['/', 'selectedset', this.exerciseset._id, i]);
  }
}