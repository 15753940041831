import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class NotAuthGuard {
  constructor(private authService: AuthService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.authService.user.getValue() != null) {
      this.authService.setUrl(state.url);
      this.router.navigate(['/home']);
      return false
    }

    const user = await this.authService.autoLogin();
    if (user == null) {
      return true
    } else {
      this.authService.setUrl(state.url);
      this.router.navigate(['/home']);
      return false;
    }

  }
}