import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintService } from '../../print-layout/print.service';
import { LayoutService } from '../../shared/layout.service';
import { ProfilmExerciseType } from '../../shared/profilm-exercise-type.model';
import { ExerciseSet } from '../exercise-set.model';
import { ExerciseSetProvider } from '../exercise-set.provider';
import { ExerciseSetService } from '../exercise-set.service';
import { Exercise } from '../exercise/exercise.model';

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise-set-content.component.html',
  styleUrls: ['./exercise-set-content.component.scss']
})
export class ExerciseSetContentComponent {
  types: ProfilmExerciseType[];
  selectedExerciseSet: ExerciseSet = null;
  selectedExercise: Exercise = new Exercise('');
  selectedCustomerdimension: any;
  saved = false;

  constructor(
    private route: ActivatedRoute,
    private service: ExerciseSetService,
    public router: Router,
    public layout: LayoutService,
    private printService: PrintService,
    private exerciseSetProvider: ExerciseSetProvider
  ) {
    this.route.data.subscribe((value => {
      this.selectedExerciseSet = value.selectedExerciseSet;
      this.selectedExercise = value.selectedExercise;
      this.selectedCustomerdimension = value.selectedCustomerdimension;
      // Tritt ein wenn man auf ein Übungsset navigiert welches garnicht existiert
      if (!this.selectedExerciseSet) {
        return;
      }
      if (!!this.selectedExercise) {
        this.layout.exercisePanelOpen = true;
        this.service.setSelectedExercise(this.selectedExercise);
      } else if (!!this.selectedCustomerdimension) {
        this.layout.exercisePanelOpen = true;
      } else {
        this.layout.exercisePanelOpen = false;
        this.router.navigate(['/', 'selectedset', this.selectedExerciseSet._id]);
      }
    }));
  }
}