import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { ProfilmService } from '../../shared/profilm.service';
import { User } from '../../useradmin/user.model';
import { UseradminService } from '../../useradmin/useradmin.service';
import { ExerciseSet } from '../exercise-set.model';
import { ExerciseSetService } from '../exercise-set.service';

@Component({
  selector: 'app-exercise-set-editor',
  templateUrl: './exercise-set-editor.component.html',
  styleUrls: ['./exercise-set-editor.component.scss']
})
export class ExerciseSetEditorComponent implements OnInit {
  @Input() exerciseset: ExerciseSet;
  public username: string;
  public users: User[] = [];
  public customerLogo;

  constructor(
    public useradminservice: UseradminService,
    public exerciseSetService: ExerciseSetService,
    public authService: AuthService,
    private http: HttpClient
  ) {
  }

  async ngOnInit() {
    this.users = await this.useradminservice.getUsers();
  }

  languageChanged() {
    this.exerciseset.inEnglish ? ProfilmService.language = 'en' : ProfilmService.language = 'de';
    this.exerciseSetService.changeScaleIfNeeded(this.exerciseset);
  }

  public async onUpload(file) {
    try {
      let formData = new FormData();
      formData.append('exsetid', this.exerciseset._id);
      formData.append('file', file.target.files[0]);
      let res = await firstValueFrom(this.http.post('/api/logo/upload', formData));
      await this.exerciseSetService.getAllCustomerLogos();
      this.customerLogo = null;
    } catch (error) {
      return [];
    }
  }
}
