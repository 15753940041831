@if(isAuthenticated && !this.layoutService.printView){
<nav class="navbar navbar-expand navbar-dark primary-bg fixed-top">
  <div class="container-fluid">
    <a
      class="navbar-brand"
      routerLink="/"
    >Kompetenzomat</a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarResponsive"
      aria-controls="navbarResponsive"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      id="navbarResponsive"
    >
      <ul class="navbar-nav ms-auto flex-row float-end ">
        <li
          class="nav-item nav-item-icon"
          [ngClass]="{'active':router.url.substr(0, 10) != '/useradmin'}"
        >
          <a
            class="nav-icon"
            [routerLink]="['/home']"
          >
            <i class="ri-organization-chart"></i>
          </a>
        </li>
        <li
          *ngIf="isAdmin"
          class="nav-item nav-item-icon"
          routerLinkActive="active"
        >
          <a
            class="nav-icon"
            [routerLink]="['/useradmin']"
          >
            <i class="ri-settings-2-line"></i>
          </a>
        </li>
        <div class="d-block shadow topbar-divider"></div>
        <div class="nav-user-img cursor-default unselectable">
          <span
            class="round-nav"
            placement="top"
            ngbTooltip="Tooltip on top unselectable"
          >{{user.name | initials}}</span>
        </div>
        <li class="nav-item nav-item-icon">
          <button
            type="button"
            class="btn nav-link btn-sm btn-slick p-0 px-2 me-0 text-white"
            (click)="this.checkDirtyAndManageLogout()"
          >
            <i class="ri-logout-box-r-line"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid header fixed-top text-center text-white">
  <div *ngIf="this.router.url.substr(0, 10) == '/useradmin'">
    <h1 class="header-title">ADMIN</h1>
    <p class="header-subtitle font-italic small">
      „Die Macht mit Bedacht einsetzen du musst.“
    </p>
  </div>
</div>
}


<div
  class="router-content content"
  [ngClass]="{'pe-3':this.isAuthenticated, 'p-0':!this.isAuthenticated && !this.layoutService.printView, 'px-3': this.layoutService.printView}"
>
  <router-outlet></router-outlet>
</div>

<div
  class="loading d-flex justify-content-center align-items-center"
  *ngIf="this.loadingService.isLoading"
>
  <div
    class="spinner-grow loader"
    role="status"
    style="width: 3rem; height: 3rem;"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
  <!-- TODO add spin animation -->
</div>