export class AcrossCompetencie {
  dimensions: any[];
  public static createByObject(o: AcrossCompetencie): AcrossCompetencie {
    const newOne = new AcrossCompetencie(o.name);
    newOne.name = o.name;
    newOne.dimensions = o.dimensions;
    return newOne;
  }

  constructor(public name: string) {
    this.dimensions = [];
  }


}
