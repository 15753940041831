import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user.model';
import { ExerciseSetService } from '../../exercise-sets/exercise-set.service';
import { ProfilmDimension } from '../../shared/profilm-dimension.model';
import { ProfilmExerciseType } from '../../shared/profilm-exercise-type.model';
import { ProfilmService } from '../../shared/profilm.service';
import { UseradminService } from '../../useradmin/useradmin.service';

@Component({
  selector: 'app-startpage-content',
  templateUrl: './startpage-content.component.html',
  styleUrls: ['./startpage-content.component.scss']
})
export class StartpageContentComponent implements OnInit {
  user: User;
  setcount: number;
  usercount: number;
  dimensioncount: number;
  ankercount: number;
  coredimension: ProfilmDimension[];
  exerciseTypes: ProfilmExerciseType[];

  constructor(private authService: AuthService, private exerciseSetService: ExerciseSetService,
    public useradminservice: UseradminService, private profilmservice: ProfilmService) {
  }

  async ngOnInit(): Promise<void> {
    this.exerciseTypes = this.profilmservice.getExerciseTypes();
    this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
    const exercisesetresult = await this.exerciseSetService.getExerciseSets();
    this.setcount = exercisesetresult.length;
    const userlistresult = await this.useradminservice.getUsers();
    this.usercount = userlistresult.length;
    this.coredimension = this.profilmservice.getDimensions();
    this.dimensioncount = this.profilmservice.getAllSubDimensions().length;
    this.ankercount = this.profilmservice.getAllAnchors().length;
  }
}