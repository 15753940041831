import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ProfilmExerciseType } from '../../shared/profilm-exercise-type.model';
import { ProfilmQuestionModel } from '../../shared/profilm-question.model';
import { ProfilmSubDimension } from '../../shared/profilm-sub-dimension.model';
import { ProfilmService } from '../../shared/profilm.service';
import { PreDefinedCompetencies } from '../../shared/profilm.types';
import { AcrossCompetencie } from '../across-competencies/across-competencie.model';
import { ExerciseSet } from '../exercise-set.model';
import { ExerciseSetService } from '../exercise-set.service';
import { Dimension, Exercise, Question } from '../exercise/exercise.model';

@Component({
  selector: 'app-exercise-details',
  templateUrl: './exercise-details.component.html',
  styleUrls: ['./exercise-details.component.scss']
})
export class ExerciseDetailsComponent implements OnInit {
  @ViewChild('dimensionSelect') dimensionSelect: NgSelectComponent;
  @ViewChild('dimensionSelect2') dimensionSelect2: NgSelectComponent;
  @ViewChild('introSelect') introQuestionSelect: NgSelectComponent;
  @Input() exerciseset: ExerciseSet;
  public exerciseTypes: ProfilmExerciseType[];
  public selectedExercise: Exercise = new Exercise('');
  public selectableDimensions: PreDefinedCompetencies[];

  public allIntroQuestions: ProfilmQuestionModel[];

  public openedIndex = null;

  constructor(
    private aRoute: ActivatedRoute,
    public service: ExerciseSetService,
    public profilMservice: ProfilmService) {


    aRoute.data.subscribe((data) => {
      this.selectedExercise = data.selectedExercise;
      this.selectedExercise.types = this.selectedExercise.types.map((t) => {
        return new ProfilmExerciseType(t.de, t.fullde, t.en, t.fullen);
      })
    });

  }

  selectNewAccordion(index: number) {
    if (index == this.openedIndex) {
      this.openedIndex = null;
    } else {
      this.openedIndex = index;
    }
  }

  onAddDimension(e) {
    if (!e) {
      return;
    }
    const dimension: Dimension = e;
    this.service.onExerciseSelectionChanged.getValue().dimensions.push(Dimension.createFromProfilmSubDimension(dimension));
    this.dimensionSelect.clearModel();
  }
  onAddPredefinedDimension(e) {
    if (!e) {
      return;
    }
    if (e instanceof ProfilmSubDimension) {
      this.service.onExerciseSelectionChanged.getValue().dimensions.push(Dimension.createFromProfilmSubDimension(e));
    }
    if (e instanceof AcrossCompetencie) {
      const dimension: Dimension = new Dimension(e as unknown as Dimension);
      this.service.onExerciseSelectionChanged.getValue().dimensions.push(dimension);
    }
    this.dimensionSelect2.clearModel();
  }
  public onAddIntroQuestion(e) {
    if (e == null) {
      return;
    }
    if (!this.service.onExerciseSelectionChanged.getValue().introQuestions) {
      this.service.onExerciseSelectionChanged.getValue().introQuestions = [];
    }
    this.service.onExerciseSelectionChanged.getValue().introQuestions.push(new Question(e));
    this.introQuestionSelect.clearModel();
  }

  ngOnInit(): void {
    this.allIntroQuestions = this.profilMservice.getIntroQuestions2();
    if (this.exerciseset.acrossCompetencies && this.exerciseset.acrossCompetencies.length > 0) {
      this.selectableDimensions = this.exerciseset.acrossCompetencies;
    }
  }
  groupByFn = (item) => item.parentDimension.name;
  groupValueFn = (_: string, children: any[]) => ({ name: children[0].parentDimension.name });
  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.parentDimension.name.toLowerCase().indexOf(term) > -1;
  }


  reArrangeDimensions(event: any) {
    moveItemInArray(this.selectedExercise.dimensions, event.previousIndex, event.currentIndex);
  }

  reArrangeIntroQuestions(event: any) {
    moveItemInArray(this.selectedExercise.introQuestions, event.previousIndex, event.currentIndex);
  }

}



