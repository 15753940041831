<div
  class="d-md-none text-white position-fixed"
  style="top: 70px; left: 0px; z-index: 1000;background-color: white;color: black !important;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    padding: 2px;"
>
  <span (click)="sidebarVisible = !sidebarVisible">
    Sidebar <i class="ri-menu-line ri-md bg-secondary text-white rounded-pill p-1"></i>
  </span>
</div>


<div
  class="d-md-none"
  style="z-index: 1000; position: fixed; width: 100vw;background-color: white;"
  *ngIf="sidebarVisible"
>
  <app-exercise-sets-sidebar-content (selectedSet)="sidebarVisible = false"></app-exercise-sets-sidebar-content>
</div>

<div class="row flex-xl-nowrap sidebar-container  me-0">
  <div class="d-none d-md-flex col-md-auto bd-sidebar sidebarBackground pe-0 ">
    <app-exercise-sets-sidebar-content (selectedSet)="sidebarVisible = false"></app-exercise-sets-sidebar-content>
  </div>
  <main
    class="col pe-0"
    role="main"
  >
    <app-startpage-content></app-startpage-content>
  </main>
</div>