import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from 'src/app/shared/layout.service';
import { ProfilmExerciseType } from 'src/app/shared/profilm-exercise-type.model';
import { ProfilmService } from 'src/app/shared/profilm.service';
import { AuthService } from '../../auth/auth.service';
import { ExerciseSet } from '../../exercise-sets/exercise-set.model';
import { ExerciseSetService } from '../../exercise-sets/exercise-set.service';
import {
  Dimension,
  Exercise,
} from '../../exercise-sets/exercise/exercise.model';

export class DimensionPage {
  dimensions: Dimension[] = [];
}

@Component({
  selector: 'app-exercise-print',
  templateUrl: './exercise-print.component.html',
  styleUrls: ['./exercise-print.component.scss'],
})
export class ExercisePrintComponent implements OnInit, AfterViewInit {
  public exerciseSet: ExerciseSet;
  public exercise: Exercise;
  public dimensionPages: DimensionPage[] = [];
  public isEnglish: boolean = false;
  public linesForNotes: number = 0;

  constructor(
    private aRoute: ActivatedRoute,
    public exerciseSetService: ExerciseSetService,
    public authService: AuthService,
    public ProfilmService: ProfilmService,
    private layoutService: LayoutService
  ) {
    this.layoutService.printView = true;
    this.aRoute.data.subscribe((data) => {
      this.exerciseSet = data.selectedExerciseSet;
      if (this.exerciseSet.inEnglish) {
        this.isEnglish = true;
      }
      this.exercise = data.selectedExercise;
    });
  }

  ngOnInit(): void {
    // @ts-ignore
    window.footerTemplate = '<div id="footer-template" style="font-size:8px !important; color:rgb(50, 88, 140); padding-left:60px">'
      + this.exerciseSet.customername + '&nbsp;&sdot;&nbsp;' + this.getHeaderText()
      + (this.isEnglish ? '&nbsp;&sdot; Page&nbsp;' : '&nbsp;&sdot; Seite&nbsp;')
      + '<span class="pageNumber"></span>'
      + (this.isEnglish ? '&nbsp;of&nbsp;' : '&nbsp;von&nbsp;') + '<span class="totalPages"></span></div>';
    let dimensionsPerSite = 1;
    if (!this.isExerciseInterview(this.exercise)) {
      dimensionsPerSite = 3;
    }
    let counter = 1;
    let dimensionPage = new DimensionPage();
    this.exercise.dimensions.forEach((dimension: Dimension) => {
      dimensionPage.dimensions.push(dimension);
      if (dimensionPage.dimensions.length === dimensionsPerSite || counter === this.exercise.dimensions.length) {
        this.dimensionPages.push(dimensionPage);
        dimensionPage = new DimensionPage();
      }
      counter++;
    });
    if (this.exercise.dimensions.length > this.exerciseSet.scale.length) {
      if (this.exercise.dimensions.length < 13) {
        // set to 13 to get at least 5 lines for notes
        this.linesForNotes = 18 - this.exercise.dimensions.length;
      }
    } else {
      if (this.exerciseSet.scale.length < 13) {
        // set to 13 to get at least 5 lines for notes
        this.linesForNotes = 18 - this.exerciseSet.scale.length;
      }
    }
  }

  public getHeaderText(): string {
    return (this.isEnglish ? 'Observation sheet' : 'Beobachtungsbogen') + ": " + this.exercise.name
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    window.isReadyForExport = true;
  }

  hasExerciseIntroQuestions(exercise: Exercise, finalQuestions: boolean): boolean {
    if (!exercise.introQuestions) {
      return false;
    }
    if (finalQuestions) {
      return exercise.introQuestions.some((q) => q.type === 'Schlussfrage');
    }
    return exercise.introQuestions.some((q) => q.type !== 'Schlussfrage');
  }

  hasExerciseRoleplayOrCaseStudy(exercise: Exercise): boolean {
    let hasExerciseRoleplayOrCaseStudy = false;
    exercise.types.forEach((element: ProfilmExerciseType) => {
      if ((element.de === this.ProfilmService.getFs().name || element.en === this.ProfilmService.getFs().name) || (element.de === this.ProfilmService.getRs().name || element.en === this.ProfilmService.getRs().name)) {
        hasExerciseRoleplayOrCaseStudy = true;
      }
    });
    return hasExerciseRoleplayOrCaseStudy;
  }

  isExerciseInterview(exercise: Exercise): boolean {
    let isExerciseInterview = false;

    if (exercise.types.length === 1 && (exercise.types[0].de === this.ProfilmService.getItw().name || exercise.types[0].en === this.ProfilmService.getItw().name)) {
      isExerciseInterview = true;
    }

    return isExerciseInterview;
  }
}
