<app-sets-menu-operations
  [exerciseset]="exerciseset"
  [selectedexercise]="exerciseset"
></app-sets-menu-operations>
<div class="row">
  <div class="col">
    <div class="card set-view-card">
      <div class="card-header">
        <app-sets-menu
          [exerciseset]="exerciseset"
          label="Matrix"
        ></app-sets-menu>
      </div>
      <div class="card-body"><app-sets-modiefied-label [exerciseset]="exerciseset"></app-sets-modiefied-label>
        <ng-template #nodata>
          <div class="alert alert-info mt-3">
            <b>Es wurde keine Übung hinterlegt.</b><br>
            Füge eine Übung hinzu um die Matrix nutzen zu können.<br>
            <br>

            <ol>
              <li>Wechsel zurück in die <a [routerLink]="['/','selectedset',exerciseset._id]">Übersicht</a> Anischt.
              </li>
              <li>Erstelle mindestens eine Übung.</li>
            </ol>


          </div>
        </ng-template>
        <div
          class="row"
          *ngIf="exerciseset.exercises.length > 0; else nodata"
        >
          <div class="col">
            <table class="table table-sm features-table">
              <tbody>
                <tr
                  *ngFor="let row of matrixData"
                  style="vertical-align:middle"
                >
                  <td *ngFor="let col of row">
                    <div
                      *ngIf="isString(col)"
                      class="inline-block"
                      style="line-height: 20px!important;"
                    >{{col}}</div>
                    <i
                      class="ri-check-line"
                      *ngIf="col===true"
                    ></i>
                  </td>
                </tr>
                <tr style="vertical-align:middle">
                  <td class="last-td-first"></td>
                  <td
                    class="last-td"
                    [attr.colspan]="matrixData[0].length-1"
                  >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>