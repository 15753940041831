import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {exhaust, exhaustMap, switchMap, take} from 'rxjs/operators';
import { HttpRequest } from '@angular/common/http';
import {ExerciseSetService} from './exercise-set.service';

@Injectable()
export class ExerciseSetsResolver  {

  constructor(private exService: ExerciseSetService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const sets = await this.exService.getExerciseSets();
    return sets;
  }
}

