import { Component, Input } from '@angular/core';
import { UseradminService } from 'src/app/useradmin/useradmin.service';
import { ExerciseSet } from '../../exercise-set.model';

@Component({
  selector: 'app-sets-modiefied-label',
  templateUrl: './sets-modiefied-label.component.html',
  styleUrl: './sets-modiefied-label.component.scss'
})
export class SetsModiefiedLabelComponent {

  @Input({ required: true }) exerciseset: ExerciseSet;

  constructor(public userservice: UseradminService) { }

}
