import { Component, Input } from '@angular/core';
import { Exercise } from '../../exercise-sets/exercise/exercise.model';
import { ProfilmExerciseType } from '../profilm-exercise-type.model';

@Component({
  selector: 'app-exercise-types-check-box',
  templateUrl: './exercise-types-check-box.component.html',
  styleUrls: ['./exercise-types-check-box.component.scss']
})
export class ExerciseTypesCheckBoxComponent {
  @Input() exercisetype: ProfilmExerciseType;
  @Input() exercise: Exercise;

  toggle(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();

    const exercise = Object.assign(new Exercise(''), this.exercise);
    exercise.toggleType(this.exercisetype);

  }
  isActive() {
    return this.exercise.types.some(t => t.de === this.exercisetype.name || t.en === this.exercisetype.name);
  }
}