<div
  class="row"
  [ngClass]="{
    'fixed-top pe-4 ':isSticky,
    'invisible': !exerciseset
  }"
>
  <div
    class="col"
    [ngClass]="{'pe-0 ':isSticky && layout.exercisePanelOpen}"
  >
    <div class="row">
      <div
        class="col-auto pe-0"
        style="z-index: 1000"
      >
        <button
          class="cursor-pointer text-danger btn-sm btn-slick "
          (click)="this.openUebungssetModal()"
        >
          <i class="ri-delete-bin-line d-none d-xl-inline"></i>
          Löschen
        </button>
      </div>

      <div
        class="col-auto pe-0"
        style="z-index: 1000"
      >
        <button
          style="z-index: 1000"
          class="cursor-pointer btn-sm btn-slick text-white"
          (click)="onPrintBtnClick(exerciseset)"
        >
          <i class="ri-printer-line d-none d-xl-inline"></i>
          Drucken
        </button>
      </div>
      <div
        class="col-auto pe-0"
        style="z-index: 1000"
      >
        <button
          class="cursor-pointer btn-sm btn-slick text-white"
          (click)="onCopyBtnClick(exerciseset)"
        >
          <i class="ri-file-copy-line d-none d-xl-inline"></i>
          Duplizieren
        </button>
      </div>
      <div
        class="col-auto pe-0"
        style="z-index: 1000"
      >
        <button
          class="cursor-pointer btn-sm btn-slick text-white"
          (click)="onSaveBtnClick(exerciseset, $event)"
        >
          <span
            *ngIf="exerciseset"
            [ngClass]="{
          'p-1 px-2 mx-n2 rounded-pill text-danger bg-white': exerciseset.getIsDirty()
          }"
          >
            <i
              class="ri-save-line"
              *ngIf="!saved"
            ></i>
            <i
              class="ri-check-line"
              *ngIf="saved"
            ></i>
            Speichern
          </span>
        </button>
      </div>
    </div>
  </div>


  <!-- das RECHTE MENU -->
  <!--
  [ngClass]="{
  'col-6 ':layout.exercisePanelOpenBol && isSticky,
  'col-7':layout.exercisePanelOpenBol && !isSticky
  }"
  -->
  <div
    class="col-auto"
    *ngIf="layout.exercisePanelOpenBol"
  >
    <ul class="nav justify-content-end">
      <li
        class="nav-item"
        *ngIf="selectedexercise"
        style="z-index: 1000"
      >
        <button
          class="cursor-pointer nav-link text-danger btn-sm btn-slick"
          (click)="this.openUebungModal()"
        >
          Übung Löschen
          <i class="ri-delete-bin-line d-none d-xl-inline"></i>
        </button>
      </li>
      <li
        class="nav-item"
        *ngIf="router.url.includes('custom')"
        style="z-index: 1000"
      >
        <button
          class="cursor-pointer nav-link text-danger btn-sm btn-slick"
          (click)="this.openKompetenzModal()"
        >
          Vordefinierte Kompetenz Löschen
          <i class="ri-delete-bin-line d-none d-xl-inline"></i>
        </button>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="isSticky">
  <div style="height: 35px;"></div>
</div>