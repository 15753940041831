import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ProfilmService } from '../shared/profilm.service';
import { ScaleEntity } from './exercise-set-scale/exercise-scale.model';
import { ExerciseSet } from './exercise-set.model';
import { Exercise } from './exercise/exercise.model';

export interface FileMetadata {
  exsetid: string;
  lastModified: number;
  mimeType: string;
  name: string;
  size: number;
  type: string;
  uploadDate: string;
}

export interface File {
  chunkSize: number;
  contentType: string;
  filename: string;
  length: number;
  md5: string;
  metadata: FileMetadata;
  uploadDate: string;
  _id: string;
}
@Injectable({
  providedIn: 'root'
})
export class ExerciseSetService {
  private exerciseSets: ExerciseSet[] = null;
  public folders: string[] = [];
  private myExerciseSets: ExerciseSet[] = null;
  public myFolders: string[] = [];
  public exsetidToCustomerLogo: Map<string, File> = new Map<string, File>();
  public onExerciseSelectionChanged = new BehaviorSubject<Exercise>(null);

  constructor(private http: HttpClient, private authService: AuthService, private profilmService: ProfilmService) {
    authService.user.subscribe((user) => {
      if (user == null) {
        this.logout();
      }
    });
  }

  public onAddFolder(event) {
    if (event && event.text) {
      event = event.text;
    }
    if (event && !this.folders.includes(event)) {
      this.folders.push(event);
      this.myFolders.push(event);
    }
    this.deleteEmptyFolders();
  }

  public deleteEmptyFolders() {
    this.folders = this.folders.filter(folder => this.exerciseSets.some(ex => ex.folder === folder));
    this.myFolders = this.myFolders.filter(folder => this.myExerciseSets.some(ex => ex.folder === folder));
  }


  public async getAllCustomerLogos() {
    const url = '/api/logo/docsforuser';
    const result: File[] = await this.http.get<File[]>(url).toPromise();
    result.forEach(r => {
      this.exsetidToCustomerLogo.set(r.metadata.exsetid, r);
    });
  }

  public logout(): void {
    this.exerciseSets = null;
    this.onExerciseSelectionChanged.next(null);
  }

  public async getExerciseSets(): Promise<ExerciseSet[]> {
    if (this.exerciseSets == null) {
      await this.fetchExerciseSets();
    }
    await this.getAllCustomerLogos();
    return this.exerciseSets;
  }

  public async getMyExerciseSets(): Promise<ExerciseSet[]> {
    if (this.myExerciseSets == null) {
      await this.fetchExerciseSets();
    }
    await this.getAllCustomerLogos();
    return this.myExerciseSets;
  }

  /** GETTING EXERCISE */
  public getExercise(selSet: ExerciseSet, exIndex): Exercise {
    if (!selSet) {
      return null;
    }
    if (!selSet.exercises[exIndex]) {
      return null;
    }
    if (!selSet.exercises[exIndex].customerDimensions) {
      selSet.exercises[exIndex].customerDimensions = [];
    }
    return selSet.exercises[exIndex];
  }

  public hasExerciseInterviewType(ex: Exercise): boolean {
    return ex.types.find(element => element.name === this.profilmService.getItw().name) != null;
  }

  public async getExerciseSet(id: string): Promise<ExerciseSet> {
    await this.getExerciseSets();
    const found = this.exerciseSets.find(element => element._id === id);
    if (!found) {
      return null;
    }
    return found;
  }

  private setExerciseSets(exerciseSets: ExerciseSet[]) {
    const allfolders: string[] = [];
    for (let i = 0; i < exerciseSets.length; i++) {
      exerciseSets[i] = ExerciseSet.createByObject(exerciseSets[i]);
      const lastSavedJson = ExerciseSet.createByObject(exerciseSets[i]);
      lastSavedJson.updateDate = '';
      exerciseSets[i].lastSavedJson = JSON.stringify(lastSavedJson);
      if (exerciseSets[i].folder) {
        allfolders.push(exerciseSets[i].folder);
      }
    }
    this.folders = allfolders.filter((value, index, array) => array.indexOf(value) === index);
    this.exerciseSets = exerciseSets;

    this.myExerciseSets = exerciseSets.filter(e => e.users.includes(this.authService.user.value._id));
    this.myFolders = this.folders.filter(f => exerciseSets.some(e => e.folder === f && e.users.includes(this.authService.user.value._id)));
  }

  public setSelectedExercise(ex: Exercise) {
    if (!!ex) {
      this.onExerciseSelectionChanged.next(ex);
    }
  }

  private async fetchExerciseSets() {
    return this.http.get<ExerciseSet[]>('/api/exercise-sets/')
      .pipe(
        tap(exerciseSets => {
          this.setExerciseSets(exerciseSets as ExerciseSet[]);
        })).toPromise();
  }

  public deleteExerciseSet(exerciseSet: ExerciseSet): Observable<ExerciseSet> {
    return this.http.delete<ExerciseSet>('/api/exercise-sets', {
      params: new HttpParams().set('_id', exerciseSet._id)
    }).pipe(
      tap(msg => {
        const index: number = this.exerciseSets.findIndex(element => element._id === msg._id);
        this.exerciseSets.splice(index, 1);
        const myIndex: number = this.myExerciseSets.findIndex(element => element._id === msg._id);
        this.myExerciseSets.splice(myIndex, 1);
      })
    );
  }

  public updateExerciseSet(exerciseSet: ExerciseSet): Observable<ExerciseSet> {
    const user = this.authService.getUser();
    const sendMeSet = ExerciseSet.createByObject(exerciseSet);
    delete sendMeSet.lastSavedJson;
    sendMeSet.updateUserId = user._id;
    return this.http.put<ExerciseSet>('/api/exercise-sets', sendMeSet).pipe(tap(msg => {
      msg.lastSavedJson = '';
      msg.updateDate = '';
      exerciseSet.lastSavedJson = JSON.stringify(msg);
      msg.lastSavedJson = JSON.stringify(msg);
    }));
  }

  public async addExerciseSet(exerciseSet: ExerciseSet, defaultEmptyScales: boolean = true): Promise<ExerciseSet> {
    const user = this.authService.getUser();
    const sendMeSet = ExerciseSet.createByObject(exerciseSet);
    sendMeSet.updateUserId = user._id;
    if (defaultEmptyScales) {
      sendMeSet.scale.push(new ScaleEntity('1', 'schwach ausgeprägt, zentraler und wichtiger Entwicklungsaspekt'));
      sendMeSet.scale.push(new ScaleEntity('2', 'Potenzial vorhanden, jedoch klares Lernfeld'));
      sendMeSet.scale.push(new ScaleEntity('3', 'durchschnittliche Ausprägung'));
      sendMeSet.scale.push(new ScaleEntity('4', 'sehr gute Leistung, deutliche Stärken in diesem Bereich'));
      sendMeSet.scale.push(new ScaleEntity('5', 'markante Leistung, ungewöhnlich hohe Ausprägung'));
    }
    delete sendMeSet.lastSavedJson;
    const creationResponse = await this.http.post<ExerciseSet>('/api/exercise-sets', sendMeSet).toPromise();
    const createdSet = ExerciseSet.createByObject(creationResponse);
    createdSet.removeDirtyFlag();
    this.exerciseSets.unshift(createdSet);
    this.myExerciseSets.unshift(createdSet);
    return createdSet;
  }

  public async copyExerciseSet(exerciseSet: ExerciseSet): Promise<ExerciseSet> {
    const user = this.authService.getUser();
    const copiedSet = JSON.parse(JSON.stringify(exerciseSet));
    copiedSet.name += ' (Kopie)';
    copiedSet._id = undefined;
    copiedSet.updateUserId = user._id;
    return this.addExerciseSet(copiedSet, false);
  }
  public getIsDirtyFromAllSets() {
    if (this.exerciseSets == null) {
      return false;
    }
    for (const set of this.exerciseSets) {
      if (set.getIsDirty() === true) {
        return true;
      }
    }
    return false;
  }

  public getMatrixForSet(exSet: ExerciseSet): any[][] {
    const dimToExToBool: Map<string, Map<Exercise, boolean>> = new Map<string, Map<Exercise, boolean>>();
    const allExersicesToName: Map<Exercise, string> = new Map<Exercise, string>();

    const res: any[][] = [];
    const row: string[] = ['Dimension'];
    exSet.exercises.forEach(ex => {
      allExersicesToName.set(ex, ex.name);
      ex.dimensions.forEach(dim => {
        if (dim.profilmSubDimIdent) {
          if (!dimToExToBool.has(dim.profilmSubDimIdent)) {
            dimToExToBool.set(dim.profilmSubDimIdent, new Map<Exercise, boolean>());
          }
          dimToExToBool.get(dim.profilmSubDimIdent).set(ex, true);
        } else {
          if (!dimToExToBool.has(dim.name)) {
            dimToExToBool.set(dim.name, new Map<Exercise, boolean>());
          }
          dimToExToBool.get(dim.name).set(ex, true);
        }
      });
    });
    row.push(...allExersicesToName.values());
    res.push(row);
    dimToExToBool.forEach((value: Map<Exercise, boolean>, key: string) => {
      const dimArr: any[] = [];
      dimArr.push(key);
      allExersicesToName.forEach((valueEx: string, keyEx: Exercise) => {
        dimArr.push(value.has(keyEx));
      });
      res.push(dimArr);
    });
    return res;
  }
  public changeScaleIfNeeded(exSet: ExerciseSet): void {
    if (JSON.stringify(exSet.scale) === JSON.stringify(this.getGermanScale())) {
      exSet.scale = this.getEnglishScale();
    } else if (JSON.stringify(exSet.scale) === JSON.stringify(this.getEnglishScale())) {
      exSet.scale = this.getGermanScale();
    }
  }
  private getGermanScale(): ScaleEntity[] {
    const result: ScaleEntity[] = [];
    result.push(new ScaleEntity('1', 'schwach ausgeprägt, zentraler und wichtiger Entwicklungsaspekt'));
    result.push(new ScaleEntity('2', 'Potenzial vorhanden, jedoch klares Lernfeld'));
    result.push(new ScaleEntity('3', 'durchschnittliche Ausprägung'));
    result.push(new ScaleEntity('4', 'sehr gute Leistung, deutliche Stärken in diesem Bereich'));
    result.push(new ScaleEntity('5', 'markante Leistung, ungewöhnlich hohe Ausprägung'));
    return result;
  }
  private getEnglishScale(): ScaleEntity[] {
    const result: ScaleEntity[] = [];
    result.push(new ScaleEntity('1', 'Weak manifestation; a key aspect for improvement'));
    result.push(new ScaleEntity('2', 'Shows potential but still a clear learning area'));
    result.push(new ScaleEntity('3', 'Average manifestation'));
    result.push(new ScaleEntity('4', 'Very good performance; clear strengths in this area'));
    result.push(new ScaleEntity('5', 'Outstanding performance; unusually high manifestation'));
    return result;
  }
}

