<div class="card set-view-card">
  <div class="card-header d-flex align-items-center">
    <span style="font-size: 20px; font-weight: 500;"><i class="ri-list-check-3"></i>
      Übungsdetails
    </span>
  </div>
  <div class="card-body">
    <div class="row mb-3">
      <div class="col">
        <div class="row">
          <div class="col">
            <form ngForm>
              <div class="form-group">
                <!-- NAME INPUT -->
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="name"
                  placeholder="Name"
                  name="name"
                  [(ngModel)]="selectedExercise.name"
                  *ngIf="selectedExercise"
                >
              </div>
            </form>
          </div>
          <div class="col"><label for="name">Übungstyp</label>
            <div>
              <ng-container *ngFor="let type of this.profilMservice.getExerciseTypes();">
                <app-exercise-types-check-box
                  [exercise]="this.selectedExercise"
                  [exercisetype]="type"
                ></app-exercise-types-check-box>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row  mb-2"
      *ngIf="this.service.hasExerciseInterviewType(this.selectedExercise)"
    >
      <div class="col">
        <label for="name">Übergeordnete Fragen</label>
        <ng-select
          #introSelect
          (change)="this.onAddIntroQuestion($event)"
          [items]="allIntroQuestions"
          bindLabel="text"
          bindValue="text"
          groupBy="type"
          class="form-control-sm  form-control custom"
        >
        </ng-select>
      </div>
    </div>
    <div
      class="row mb-3"
      *ngIf="this.service.hasExerciseInterviewType(selectedExercise)"
    >
      <div class="col">
        <ul
          class="list-group "
          cdkDropList
          (cdkDropListDropped)="reArrangeIntroQuestions($event)"
        >
          <li
            cdkDrag
            class="list-group-item"
            *ngFor="let introquestion of selectedExercise.introQuestions; index as i "
          >
            <app-anchor-details
              [item]="introquestion"
              [items]="selectedExercise.introQuestions"
            ><i
                class="ri-menu-line"
                cdkDragHandle
              ></i>
            </app-anchor-details>
          </li>
        </ul>
      </div>
    </div>

    <div class="row  mb-2">
      <div class="col">
        <div
          class="alert alert-info"
          *ngIf="selectedExercise.types.length == 0; else competenceChooser"
        >
          <b>Es ist kein Übungstyp hinterlegt.</b><br>
          Um die Kompetenzen ausarbeiten zu können muss zuerst ein Übungstyp gewählt werden.
          <br>
          <br>
          <ul>
            <li>
              Bitte wähle mindestens einen Übungstyp (<b>ITW</b>, <b>PR</b>, <b>FS</b>, <b>RS</b>) aus um fortzufahren.
            </li>
          </ul>
        </div>

        <ng-template #competenceChooser>
          <label for="name">Kompetenz wählen</label>
          <ng-select
            [items]="this.profilMservice.getSubDimensions(this.selectedExercise.types)"
            *ngIf="!selectableDimensions"
            #dimensionSelect
            (change)="onAddDimension($event);"
            bindLabel="name"
            bindValue="name"
            [groupBy]="groupByFn"
            [groupValue]="groupValueFn"
            [searchFn]="customSearchFn"
            class="form-control-sm  form-control pb-3"
          >
            <ng-template
              let-item="item"
              ng-label-tmp
            >
              <div class="row">
                <div class="col">
                  <span>{{ item.name}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template
              ng-optgroup-tmp
              let-item="item"
              let-index="index"
            >
              <span>{{ item.name }}</span>
            </ng-template>
            <ng-template
              let-index="index"
              let-item="item"
              let-search="searchTerm"
              ng-option-tmp
            >
              <div class="row">
                <div class="col">
                  <span>{{ item.name}}</span>
                </div>
                <div class="col">
                  <span>{{ item.parentDimension.name }}</span>
                </div>
              </div>
            </ng-template>
          </ng-select>
          <ng-select
            [items]="selectableDimensions"
            *ngIf="selectableDimensions"
            #dimensionSelect2
            (change)="onAddPredefinedDimension($event);"
            bindLabel="name"
            bindValue="name"
            class="form-control-sm  form-control"
          >
            <ng-template
              let-item="item"
              ng-label-tmp
            >
              <div class="row">
                <div class="col">
                  <span>{{ item.name}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template
              ng-optgroup-tmp
              let-item="item"
              let-index="index"
            >
              <span>{{ item.name }}</span>
            </ng-template>
            <ng-template
              let-index="index"
              let-item="item"
              let-search="searchTerm"
              ng-option-tmp
            >
              <div class="row">
                <div class="col">
                  <span>{{ item.name}}</span>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </ng-template>



        <cdk-accordion
          class="list-group mt-2"
          [multi]="false"
          cdkDropList
          (cdkDropListDropped)="reArrangeDimensions($event)"
        >
          @for ( dimension of selectedExercise.dimensions; track dimension; let index = $index) {
          <div
            class="list-group-item  hover-mark"
            [ngClass]="{'selected-mark': openedIndex == index}"
            cdkDrag
          >
            <app-dimension-details
              [dimension]="dimension"
              [selectedExercise]="selectedExercise"
              [exerciseSet]="exerciseset"
              [index]="index"
              (opened)="selectNewAccordion($event)"
            >
              <i
                class="ri-menu-line"
                style="cursor: ns-resize;"
                cdkDragHandle
              ></i>

            </app-dimension-details>
          </div>
          }
        </cdk-accordion>
      </div>
    </div>
  </div>
</div>