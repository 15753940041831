import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { User } from './user.model';
import { UseradminService } from './useradmin.service';

@Component({
  selector: 'app-useradmin',
  templateUrl: './useradmin.component.html',
  styleUrls: ['./useradmin.component.scss']
})
export class UseradminComponent {
  errorMessage: string = null;
  newUserIsOpen = true;
  public Users: User[];
  currentDetailedUser: User;
  saved = false;

  constructor(
    private route: ActivatedRoute,
    private useradminService: UseradminService,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.route.data.subscribe(value => {
      this.Users = value.userList;
    });
  }

  public openUserAdminModal() {
    const modalUserAdminRef = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog modal-centered',
      keyboard: false,
      initialState: {
        modalMsg: 'Möchtest du wirklich diesen <u>Benutzer löschen</u>?<br> Diese Aktion kann nicht rückgängig gemacht werden.'
      }
    });
    modalUserAdminRef.content?.hasConfirmed.subscribe((result) => {
      if (result) {
        this.onTrashBtnClick(this.currentDetailedUser);
        modalUserAdminRef.hide();
      }
    });
  }

  onSaveBtnClick(user) {
    this.saved = true;
    this.useradminService.changeUser(user.name, user.email, user.isadmin, user._id).subscribe(r => {
      setTimeout(() => {
        this.saved = false;
      }, 1000);
    });
  }

  onTrashBtnClick(user) {
    this.useradminService.deleteUser(user._id).subscribe(resData => {
      const index = this.Users.indexOf(user);
      this.Users.splice(index, 1);
      this.currentDetailedUser = null;
      this.router.navigate(['/useradmin']);
    });
  }

  async onSubmit(form: NgForm) {
    this.errorMessage = null;
    if (!form.valid) {
      return;
    }
    this.useradminService.addNewUser(form.value.name, form.value.email, form.value.isadmin);
    form.reset();
  }

  showUserDetails(user) {
    this.newUserIsOpen = false;
    this.currentDetailedUser = user;
  }
}
