import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProfilmService } from '../shared/profilm.service';
import { ExerciseSetService } from './exercise-set.service';

@Injectable()
export class SelectedExerciseSetResolver {

  constructor(
    private exService: ExerciseSetService,
    private ProfilmService: ProfilmService
  ) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.ProfilmService.getProfilmData();
    const set = await this.exService.getExerciseSet(route.params.id);
    set.inEnglish ? ProfilmService.language = 'en' : ProfilmService.language = 'de';
    return set;
  }
}

