<div class="row">
  <div class="col">
    <form ngForm>
      <div class="form-group">
        <label for="name">Name</label>
        <input
          type="text"
          class="form-control form-control-sm"
          id="name"
          placeholder="Name"
          name="name"
          [(ngModel)]="exerciseset.name"
        >
      </div>
    </form>
  </div>
  <div class="col">
    <app-exercise-user-list [exerciseset]="exerciseset"></app-exercise-user-list>
  </div>
</div>
<div class="row">
  <div class="col">
    <form ngForm>
      <div class="form-group">
        <label for="folder">Gruppe</label>
        <ng-select
          id="folder"
          name="folder"
          [(ngModel)]="exerciseset.folder"
          (change)="exerciseSetService.onAddFolder($event)"
          [items]="exerciseSetService.myFolders"
          [addTag]="true"
          bindLabel="text"
          bindValue="text"
          class="form-control-sm form-control custom groupselect"
        >
        </ng-select>
      </div>
    </form>
  </div>
  <div class="col">
    <form ngForm>
      <div class="form-group">
        <label for="language">Sprache</label>
        <ng-select
          class="form-control form-control-sm"
          id="language"
          name="language"
          [clearable]="false"
          [(ngModel)]="exerciseset.inEnglish"
          (ngModelChange)="languageChanged()"
        >
          <ng-option [value]="false">Deutsch</ng-option>
          <ng-option [value]="true">Englisch</ng-option>
        </ng-select>

      </div>
    </form>
  </div>
</div>
<div class="row">
  <div class="col">
    <form ngForm>
      <div class="form-group">
        <label for="customername">Kundenname</label>
        <input
          type="text"
          class="form-control form-control-sm"
          id="customername"
          placeholder="Kundenname"
          name="customername"
          [(ngModel)]="exerciseset.customername"
        >
      </div>
    </form>
  </div>
  <div class="col">
    <form ngForm>
      <label for="customerlogo">Kundenlogo</label>
      <div class="form-group-sm form-group custom-file">
        <input
          [(ngModel)]="this.customerLogo"
          (change)="onUpload($event)"
          type="file"
          class="custom-file-input form-control form-control-sm"
          id="customerlogo"
          placeholder="Kundenname"
          name="customerlogo"
          lang="de"
        >
        <label
          class="custom-file-label "
          for="customerlogo"
        >Logo auswählen ...</label>
      </div>
    </form>
    <img
      class="logo-preview"
      *ngIf="this.exerciseSetService.exsetidToCustomerLogo.has(exerciseset._id)"
      [src]="'/api/logo/load/' + exerciseSetService.exsetidToCustomerLogo.get(exerciseset._id)._id + '?uploaddate=' + exerciseSetService.exsetidToCustomerLogo.get(exerciseset._id).uploadDate"
    />
  </div>
</div>
<div class="row">
  <div class="col">
    <form ngForm>
      <div class="form-group">
        <label for="description">Beschreibung</label>
        <textarea
          class="form-control form-control-sm"
          id="description"
          rows="3"
          name="description"
          [(ngModel)]="exerciseset.description"
        ></textarea>
      </div>
    </form>
  </div>
</div>