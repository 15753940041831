import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UseradminService } from './useradmin.service';

@Injectable()
export class UserListResolver  {

  constructor(private service: UseradminService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const users = await this.service.getUsers();
    return users;
  }
}

