import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { ExerciseSet } from '../exercise-sets/exercise-set.model';

export interface PdfExport {
  url: string;
  pdfname: string;
}
export interface ExportFilesBody {
  pdfs: PdfExport[];
  localstorageItems: any[];
}

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  constructor(private http: HttpClient) { }

  public async exportExerciseSet(exerciseSet: ExerciseSet) {
    const pdfs: PdfExport[] = [];
    exerciseSet.exercises.forEach((ex, index) => {
      const pdf = {
        url: '/print/exercise-set/' + exerciseSet._id + '/exercise/' + index,
        pdfname: ex.name + '.pdf'
      };
      pdfs.push(pdf);
    });
    const body: ExportFilesBody = {
      pdfs,
      localstorageItems: [
        { key: 'userData', val: localStorage.getItem('userData') }
      ],
    };
    const result = await this.http.post('/api/exportfiles', body, { responseType: 'blob' }).toPromise();
    saveAs(result, exerciseSet.name + '.zip');
  }
}