import { Component, Input, OnInit } from '@angular/core';
import { ProfilmExerciseType } from '../../../shared/profilm-exercise-type.model';
import { ProfilmService } from '../../../shared/profilm.service';
import { Exercise } from '../../exercise/exercise.model';

@Component({
  selector: 'app-exercise-list-item',
  templateUrl: './exercise-list-item.component.html',
  styleUrls: ['./exercise-list-item.component.scss']
})
export class ExerciseListItemComponent implements OnInit {
  @Input({ required: true }) exercise: Exercise;
  public exerciseTypes: ProfilmExerciseType[];

  constructor(private profilMservice: ProfilmService) {
  }

  ngOnInit(): void {
    this.exerciseTypes = this.profilMservice.getExerciseTypes();
  }
}
