<div class="login-dark">
  <ng-template #codeInvalid>
    <form class="animated">
      <h2 class="visually-hidden">Wrong code</h2>
      <div
        class="form-row"
        style="margin: 10px;"
      >
        <div
          class="col-auto text-center"
          style="padding: 5px;opacity: 1;filter: blur(0px);"
        >
          <div class="bg-white border rounded shadow"><img
              src="assets/ProfilM_Logo.png"
              width="100%"
              height="100%"
              style="padding: 10px;"
            /></div>
        </div>
      </div>
      <div class="wrongcodeDiv">
        <p>Ungültiger Code</p>
        <p>Möglicherweise wurde dieser Code bereits verwendet.</p>
        <br>
        <a
          href="#"
          routerLink="/"
        >Zum Login zurückkehren</a>
      </div>
    </form>
  </ng-template>
  <form
    *ngIf="validCode; else codeInvalid"
    #setPasswordForm="ngForm"
    (ngSubmit)="onSubmit(setPasswordForm)"
    [ngClass]="{'shake' : errorMessage }"
    class="animated"
  >
    <h2 class="visually-hidden">Set new Password</h2>
    <div
      class="form-row"
      style="margin: 10px;"
    >
      <div
        class="col-auto text-center"
        style="padding: 5px;opacity: 1;filter: blur(0px);"
      >
        <div class="bg-white border rounded shadow"><img
            src="assets/ProfilM_Logo.png"
            width="100%"
            height="100%"
            style="padding: 10px;"
          /></div>
      </div>
    </div>
    <p style="text-align: center">Passwort festlegen</p>
    <div class="form-group">
      <input
        type="password"
        id="password"
        class="form-control"
        ngModel
        name="password"
        required
        minlength="6"
        placeholder="Passwort"
      />
      <input
        type="password"
        id="password2"
        class="form-control"
        ngModel
        name="password"
        required
        minlength="6"
        placeholder="Passwort bestätigen"
      />
    </div>
    <div class="form-group">
      <div
        *ngIf="errorMessage"
        class="alert alert-warning alert-dismissible fade show loginerror"
        role="alert"
      >
        <p>{{errorMessage}}</p>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <button
        class="btn btn-primary btn-block"
        type="submit"
        [disabled]="!setPasswordForm.valid"
      >
        Passwort setzen</button>
    </div>
  </form>
</div>