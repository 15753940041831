<div class="row">
  <div class="col col-auto">
    <ng-content></ng-content>
  </div>

  <div class="col">
    {{ this.exercise.name }}
  </div>

  <div class="col col-auto">
    <ng-container *ngFor="let type of this.exerciseTypes;  ">
      <app-exercise-types-check-box
        [exercisetype]="type"
        [exercise]=exercise
      ></app-exercise-types-check-box>
    </ng-container>
  </div>
</div>