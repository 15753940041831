import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from './user.model';

interface AuthResponseData {
  user: {
    _id: string
    name: string
    email: string
    isadmin: boolean
  };
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private url = '/home';

  constructor(private http: HttpClient, private router: Router) { }

  setUrl(url) {
    this.url = url;
  }

  login(email: string, password: string) {
    return this.http
      .post<AuthResponseData>(
        '/api/auth/login',
        {
          email: email,
          password: password
        }
      )
      .pipe(tap(respData => {
        const user = new User(
          respData.user._id,
          respData.user.email,
          respData.user.name,
          respData.user.isadmin,
        );
        this.user.next(user);
        this.router.navigateByUrl(this.url).then();
      }));
  }

  async logout() {
    const logoutresult = await this.http.get<User>('/api/auth/logout').toPromise()
    this.user.next(null);
    this.router.navigate(['/auth']);
  }

  async autoLogin() {
    try {
      const userData = await this.http.get<User>('/api/auth/myUser').toPromise()
      const user = new User(userData._id, userData.email, userData.name, userData.isadmin);
      this.user.next(user);
      return user
    } catch (e) {
      return null
    }
  }

  requestPWRecovery(email: string) {
    return this.http.get<AuthResponseData>('/api/auth/requestNewPass', { params: new HttpParams().set('email', email) }).pipe();
  }

  private handleError(errResp: HttpErrorResponse) {
    const errMsg = 'Unknown Error (' + errResp.status + ')';
    return throwError({ msg: errMsg, nativeRespnse: errResp });
  }

  public getUser() {
    try {
      return this.user.getValue()
    } catch (e) {
      return;
    }
  }
}