import { ProfilmSubDimension } from './profilm-sub-dimension.model';
import { ProfilmService } from './profilm.service';

export class ProfilmDimension {
  constructor(public de: string, public en: string, public subDimensions: ProfilmSubDimension[]) {
  }
  public get name() {
    return ProfilmService.isLanguageDe() ? this.de : this.en;
  }
  public getSubDimensions(): ProfilmSubDimension[] {
    return this.subDimensions.slice();
  }

  public addSubDimension(subDimension: ProfilmSubDimension) {
    this.subDimensions.push(subDimension);
    subDimension.parentDimension = this;
  }


}
