import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Anchor, Question } from '../../../exercise/exercise.model';

@Component({
  selector: 'app-anchor-details',
  templateUrl: './anchor-details.component.html',
  styleUrls: ['./anchor-details.component.scss']
})
export class AnchorDetailsComponent {
  @Input() items: Anchor[] | Question[];
  @Input() item: Anchor | Question;
  @Output() changedAnchor: EventEmitter<boolean> = new EventEmitter<boolean>();

  deleteAnchor() {
    this.items.forEach((value: Anchor | Question, index, array) => {
      if (value === this.item) {
        array.splice(index, 1);
      }
    });
    this.changedAnchor.emit(true);
  }
}