<h5>
  <i class="ri-list-check-3"></i>
  Vordefinierte Kompetenzen ({{acrossCompetencies.length}})
</h5>
<ng-template #empty>
  <ul class="list-group">
    <li class="list-group-item list-group-item-info">Es sind noch keine <b>Übergreifende Kompetenzen</b> hinzugefügt
      worden.</li>
  </ul>
</ng-template>
<div
  class="list-group"
  *ngIf="(acrossCompetencies.length  > 0 ); else empty"
>
  <ng-container *ngFor="let competence of acrossCompetencies; index as i ">
    <a
      class="list-group-item list-group-item-action"
      routerLinkActive="active selected-mark"
      *ngIf="competence.dimensions"
      [routerLink]="['/','selectedset',exerciseset._id,'custom', i]"
    >
      {{competence.name}}
    </a>
    <span
      class="list-group-item list-group-item-action cursor-pointer"
      routerLinkActive="active selected-mark"
      [routerLink]="['/','selectedset',exerciseset._id,'custom', i]"
      *ngIf="!competence.dimensions"
    >
      {{competence.name}}
      <img
        src="assets/ProfilM_Logo.png"
        style="height:25px"
        class="float-end"
      >
    </span>
  </ng-container>
</div>

<div class="row pt-4">
  <div class="col">
    <label
      class="fw-bold"
      for="competenceInput"
    >Kundenkompetenz</label>
    <input
      id="competenceInput"
      class="form-control-sm form-control"
      (keydown.enter)="addCustomerCompetencie($event)"
    >
  </div>
  <div class="col">
    <b style="background-image: url(assets/ProfilM_Logo.png);
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 58px;">-Kompetenz</b>
    <ng-select
      [items]="allsubdimenstions"
      #dimensionSelect
      (change)="onAddCompetencies($event)"
      [groupBy]="groupByFn"
      [groupValue]="groupValueFn"
      [searchFn]="customSearchFn"
      bindLabel="name"
      bindValue="name"
      class="form-control-sm  form-control"
    >
      <ng-template
        let-item="item"
        ng-label-tmp
      >
        <div class="row">
          <div class="col">
            <span>{{ item.name}}</span>
          </div>
        </div>
      </ng-template>
      <ng-template
        ng-optgroup-tmp
        let-item="item"
        let-index="index"
      >
        <span style="color:#000 !important;font-size: larger; font-weight: bold"> {{ item.name }}</span>
      </ng-template>
      <ng-template
        let-index="index"
        let-item="item"
        let-search="searchTerm"
        ng-option-tmp
      >
        <div class="row">
          <div class="col-12">
            <span><b>{{ item.name}}</b></span>
          </div>
          <div class="col-12">
            <span>{{ item.parentDimension.name }}</span>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

<!--    <form class=""
          (ngSubmit)="onAddCompetencies(f)"
          #f="ngForm">
      <input type="text"
             class="form-control mb-2 me-sm-2  form-control-sm b"
             placeholder="Neue Übergreifende Kompetenzen "
             name="name"
             ngModel
             #newExerciseNameInput>
      <button class="btn btn-primary mb-2 btn-sm"
              type="submit">
        <i class="ri-add-line"></i>
        Hinzufügen
      </button>
    </form>
    -->