<ng-template #empty>
  <div class="alert alert-danger">Das Übungsset existiert nicht!</div>
</ng-template>

<app-sets-menu-operations
  [exerciseset]="exerciseset"
  [selectedexercise]="exerciseset"
></app-sets-menu-operations>

<div *ngIf="exerciseset; else empty">
  <div class="card set-view-card ">
    <div class="card-header">
      <app-sets-menu
        [exerciseset]="exerciseset"
        label="Skala"
      ></app-sets-menu>
    </div>
    <div class="card-body">
      <app-sets-modiefied-label [exerciseset]="exerciseset"></app-sets-modiefied-label>
      <div class="row mt-3">
        <div class="col">
          <ng-template #nodata>
            <div class="alert alert-info ">
              <b>Es ist keine Skala hinterlegt. </b><br>
              Füge eine Neue Skala hinzu um fortzufahren.<br>
              <br>
              <ul>
                <li>Klicke auf "Neue Skalaspalte hinzufügen".</li>
              </ul>
            </div>
          </ng-template>
          <table
            class="table table-striped"
            *ngIf="exerciseset.scale.length > 0; else nodata"
          >
            <thead>
              <tr>
                <th></th>
                <th>Wert</th>
                <th>Bedeutung</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let s of this.exerciseset.scale; index as i">
                <td style="width: 1px">
                  <i class="ri-menu-line"></i>
                </td>
                <td style="width: 1px">
                  <input
                    type="text"
                    class="form-control form-control-sm text-center"
                    id="wert{{i}}"
                    placeholder="Wert"
                    name="wert"
                    [(ngModel)]="s.value"
                  >
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="desc{{i}}"
                    placeholder="Bedeutung"
                    name="desc"
                    [(ngModel)]="s.desc"
                  >
                </td>
                <td>
                  <i
                    class="ri-delete-bin-line text-danger"
                    (click)="onTrashBtnClick(s)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            type="button"
            class="btn btn-primary mb-2 btn-sm"
            (click)="onAddBtnClick()"
          >
            <i class="ri-add-line"></i>
            Neue Skalaspalte hinzufügen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>