<div
  class="btn-group align-items-center"
  dropdown
  *ngIf="exerciseset!= null"
>
  <button
    id="button-basic"
    dropdownToggle
    type="button"
    class="btn-link btn dropdown-toggle mt-n2 p-0"
    style="font-size: 20px;"
    aria-controls="dropdown-basic"
  > {{label || "Dropdown"}} <span class="caret"></span>
  </button>
  <ul
    id="dropdown-basic"
    *dropdownMenu
    class="dropdown-menu"
    role="menu"
    aria-labelledby="button-basic"
  >
    <li role="menuitem">
      <a
        [routerLinkActiveOptions]="{exact:true, __change_detection:this.router.url}"
        routerLinkActive="active"
        class="dropdown-item "
        [routerLink]="['/','selectedset',exerciseset._id]"
      >Übersicht</a>
    </li>
    <li role="menuitem">
      <a
        [routerLinkActiveOptions]="{exact:false, __change_detection:this.router.url}"
        routerLinkActive="active"
        class="dropdown-item"
        [routerLink]="['/','selectedset',exerciseset._id,'scala']"
      >Skala</a>

    </li>
    <li role="menuitem">
      <a
        [routerLinkActiveOptions]="{exact:false, __change_detection:this.router.url}"
        routerLinkActive="active"
        class="dropdown-item"
        [routerLink]="['/','selectedset',exerciseset._id,'matrix']"
      >Matrix</a>

  </ul>
</div>