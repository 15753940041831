import { ProfilmExerciseType } from './profilm-exercise-type.model';
import { ProfilmService } from './profilm.service';

export class ProfilmAnchorModel {
  constructor(public de: string, public en: string, public exerciseTypes: ProfilmExerciseType[]) {
  }
  public static createFromObject(anchor: ProfilmAnchorModel): ProfilmAnchorModel {
    const result: ProfilmAnchorModel = new ProfilmAnchorModel(anchor.de, anchor.en, anchor.exerciseTypes);
    return result;
  }

  public hasExerciseTypes(exerciseTypes: ProfilmExerciseType[]): boolean {
    for (let i = 0; i < this.exerciseTypes.length; i++) {
      for (let y = 0; y < exerciseTypes.length; y++) {
        if (this.exerciseTypes[i].de === exerciseTypes[y].de || this.exerciseTypes[i].en === exerciseTypes[y].en) {
          return true;
        }
      }
    }
    return false;
  }
  public get text() {
    return ProfilmService.isLanguageDe() ? this.de : this.en;
  }


}
