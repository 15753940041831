<ng-template #empty>
  <div class="alert alert-danger">Der Benutzer konnte nicht gefunden werden</div>
</ng-template>
<div *ngIf="currentDetailedUser; else empty">
  <div class="row">
    <div class="col-12">
      <form>
        <div class="form-group">
          <h3 style="display: contents; color: #384d63">{{currentDetailedUser.name || "KEIN NAME"}}</h3>
          <div class="nav-user-img2 cursor-default float-end unselectable">
            <span
              class="round-nav2"
              placement="top"
              ngbTooltip="Tooltip on top"
            >{{currentDetailedUser.name | initials}}</span>
          </div>
          <br><br>
          <label for="nameDetail">Name</label>
          <input
            type="text"
            id="nameDetail"
            class="form-control"
            ngModel
            name="name"
            required
            placeholder="Name"
            [(ngModel)]="currentDetailedUser.name"
          />
        </div>
        <div class="form-group">
          <label for="emailDetail">E-Mail</label>
          <input
            type="email"
            id="emailDetail"
            class="form-control"
            ngModel
            name="email"
            required
            email
            placeholder="Email"
            [(ngModel)]="currentDetailedUser.email"
          />
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            id="isadminDetail"
            class="form-check-input"
            ngModel
            name="isadmin"
            isadmin
            placeholder="xxx"
            [(ngModel)]="currentDetailedUser.isadmin"
          />
          <small>Admin</small>
        </div>


      </form>
    </div>
  </div>
</div>