import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ExerciseSetService } from './exercise-set.service';

@Injectable()
export class MyExerciseSetsResolver {

  constructor(private exService: ExerciseSetService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const sets = await this.exService.getMyExerciseSets();
    return sets;
  }
}

