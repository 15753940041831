import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UseradminService } from '../../useradmin/useradmin.service';
import { ExerciseSet } from '../exercise-set.model';

@Component({
  selector: 'app-sets-menu',
  templateUrl: './sets-menu.component.html',
  styleUrls: ['./sets-menu.component.scss']
})
export class SetsMenuComponent {
  @Input() exerciseset: ExerciseSet;
  @Input() label = 'Dropdown';

  constructor(public router: Router, public userservice: UseradminService) { }
}