<div style="max-height: calc(100vh - 130px);">
  <div class="p-3">
    <form
      class="form-inline"
      (ngSubmit)="onAddExerciseSet(f)"
      #f="ngForm"
    >
      <div class="input-group">
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Name"
          name="name"
          required
          ngModel
          #nameInput
        >
        <button
          type="submit"
          class="btn btn-primary btn-sm"
          [disabled]="nameInput.value.trim() == ''"
        >
          <i class="ri-add-line"></i>
          Neu
        </button>
      </div>
    </form>
  </div>
  <div
    style="max-height: calc(100vh - 130px - 64px);"
    class="row overflow-scroll p-0 m-0"
  >
    <div class="col ps-0 pe-0">
      <ng-container>
        <div class="card border-0 shadow-none">
          <div
            class="card-header p-0 border-bottom-0"
            style="cursor: pointer;"
            (click)="this.toggleCollapsed('Nicht zugewiesen')"
          >
            <table class="table mb-0 ">
              <tbody>
                <tr style="background-color: #33588c; color: white">
                  <td class="col-12">
                    <i
                      class="ri-arrow-down-s-line"
                      *ngIf="this.isCollapsed('Nicht zugewiesen')"
                      style="position: relative; top:0.2rem;"
                    ></i>
                    <i
                      class="ri-arrow-up-s-line"
                      *ngIf="!this.isCollapsed('Nicht zugewiesen')"
                      style="position: relative; top:0.2rem;"
                    ></i>
                    <b>Nicht zugewiesen</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="card-body p-0"
            [collapse]="this.isCollapsed('Nicht zugewiesen')"
            [isAnimated]="true"
          >
            <table class="table  table-striped mb-0">
              <tbody>
                <ng-container *ngFor="let exerciseSet of exerciseSets">
                  <tr
                    *ngIf="exerciseSet.folder === undefined || exerciseSet.folder === null"
                    (click)="this.selectedSet.emit();"
                    [routerLinkActiveOptions]="{exact:false,__change_detection:this.router.url}"
                    routerLinkActive="active  selected-mark"
                    [routerLink]="['/','selectedset',exerciseSet._id]"
                    class="cursor-pointer hover-mark"
                  >
                    <td class="col-12">
                      <i class="ri-organization-chart"></i>
                      <span
                        *ngIf="exerciseSet.getIsDirty()"
                        class="float-end"
                      >
                        <div
                          class="badge badge-danger-invert isDirtySign"
                          style="border-radius: 50%;height: 16px;width: 16px;position: relative; top: -0.2rem; padding-left: .35rem;"
                        >!</div>
                      </span>
                      <span> {{exerciseSet.name || 'Unbekannt'}}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let folder of exerciseSetService.myFolders; index as i">
        <div class="card border-0 shadow-none">
          <div
            class="card-header p-0 bg-white border-bottom-0"
            style="cursor: pointer;"
            (click)="this.toggleCollapsed(folder)"
          >
            <table class="table mb-0">
              <tbody>
                <tr style="background-color: #33588c; color: white">
                  <td class="col-12">
                    <i
                      class="ri-arrow-down-s-line"
                      *ngIf="this.isCollapsed(folder)"
                      style="position: relative; top:0.2rem;"
                    ></i>
                    <i
                      class="ri-arrow-up-s-line"
                      *ngIf="!this.isCollapsed(folder)"
                      style="position: relative; top:0.2rem;"
                    ></i>
                    <b>{{folder}}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="card-body p-0"
            [collapse]="this.isCollapsed(folder)"
            [isAnimated]="true"
          >
            <table class="table  table-striped mb-0 ">
              <tbody>
                <ng-container *ngFor="let exerciseSet of exerciseSets">
                  <tr
                    *ngIf="exerciseSet.folder === folder"
                    (click)="this.selectedSet.emit();"
                    [routerLinkActiveOptions]="{exact:false,__change_detection:this.router.url}"
                    routerLinkActive="active selected-mark"
                    [routerLink]="['/','selectedset',exerciseSet._id]"
                    class="cursor-pointer hover-mark"
                  >
                    <td class="col-12">
                      <i class="ri-organization-chart"></i>
                      <span
                        *ngIf="exerciseSet.getIsDirty()"
                        class="float-end"
                      >
                        <div
                          class="badge badge-danger-invert isDirtySign"
                          style="border-radius: 50%;height: 16px;width: 16px;position: relative; top: -0.2rem; padding-left: .35rem;"
                        >!</div>
                      </span>
                      <span> {{exerciseSet.name || 'Unbekannt'}}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>