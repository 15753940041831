import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss'
})
export class ConfirmModalComponent {
  public modalMsg: string;
  public hasConfirmed: Subject<boolean> = new Subject<boolean>();

  constructor(public bsModalRef: BsModalRef) { }

  public confirm(): void {
    this.hasConfirmed.next(true);
  }
}
