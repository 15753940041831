import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ProfilmAnchorModel } from '../../../shared/profilm-anchor.model';
import { ProfilmQuestionModel } from '../../../shared/profilm-question.model';
import { ProfilmSubDimension } from '../../../shared/profilm-sub-dimension.model';
import { ProfilmService } from '../../../shared/profilm.service';
import { AcrossCompetencie } from '../../across-competencies/across-competencie.model';
import { ExerciseSet } from '../../exercise-set.model';
import { ExerciseSetService } from '../../exercise-set.service';
import { Anchor, Dimension, Exercise, Question } from '../../exercise/exercise.model';

@Component({
  selector: 'app-dimension-details',
  templateUrl: './dimension-details.component.html',
  styleUrls: ['./dimension-details.component.scss']
})
export class DimensionDetailsComponent implements OnInit {
  @ViewChild('anchorSelect') anchorSelect: NgSelectComponent;
  @ViewChild('questionSelect') questionSelect: NgSelectComponent;
  @Input() dimension: Dimension;
  @Input() selectedExercise: Exercise;
  @Input() exerciseSet: ExerciseSet;
  @Input() index: number;
  @Output() opened: EventEmitter<number> = new EventEmitter<number>();
  public selectableAnchor: Anchor[] = [];
  public anchorForSelection: Anchor[] = [];
  public selectableQuestions: Question[] = [];

  public tabIndex = 0;

  constructor(public service: ExerciseSetService, private profilMservice: ProfilmService) {
  }
  public getSelectableAnchor() {
    this.anchorForSelection = this.selectableAnchor.filter(sa => this.dimension.anchor.findIndex(a => a.text === sa.text) === -1);
  }
  addAnchor(e) {
    if (!e) {
      return;
    }
    const a: Anchor = new Anchor(e);
    this.dimension.anchor.push(a);
    this.getSelectableAnchor();
    this.anchorSelect.clearModel();
  }

  changedAnchor(event) {
    this.getSelectableAnchor();
  }

  addQuestion(e) {
    if (!e) {
      return;
    }
    const q: Question = new Question(e);
    this.dimension.questions.push(q);
    this.questionSelect.clearModel();
  }

  removeDimension(dimension) {
    this.selectedExercise.dimensions.forEach((value, index, array) => {
      if (value === dimension) {
        array.splice(index, 1);
      }
    });
  }

  ngOnInit(): void {
    if (this.dimension.profilmSubDimIdent) {
      const subDim = this.profilMservice.getSubDimensionByPrmIdendifier(this.dimension.profilmSubDimIdent);
      this.selectableAnchor = subDim.getAnkerOfTypes(this.selectedExercise.types);
      this.selectableQuestions = subDim.questions;
    } else {
      // IS NOW CUSTOMER DIMENSION
      const customerdim: AcrossCompetencie = this.exerciseSet.acrossCompetencies.find(c => c.name === this.dimension.name) as AcrossCompetencie;
      customerdim.dimensions.forEach((dim: ProfilmSubDimension) => {
        dim.anchor.forEach(anchor => this.selectableAnchor.push(ProfilmAnchorModel.createFromObject(anchor)));
        dim.questions.forEach(question => this.selectableQuestions.push(new ProfilmQuestionModel(question.de, question.en, question.type)));
      });
      this.selectableAnchor = this.selectableAnchor.filter((a: ProfilmAnchorModel) => a.hasExerciseTypes(this.selectedExercise.types));
    }
    this.getSelectableAnchor();
  }


  public reArrangeAnchor(event: any) {
    moveItemInArray(this.dimension.anchor, event.previousIndex, event.currentIndex);
  }

  public reArrangeQuestions(event: any) {
    moveItemInArray(this.dimension.questions, event.previousIndex, event.currentIndex);
  }
}
