<div class="row align-items-center justify-content-center">
  <div class="col-auto">
    <ng-content></ng-content>
  </div>
  <div class="col">
    <span *ngIf="item.type">{{item.type}}</span>
    <input
      type="text"
      class="form-control form-control-sm "
      id="name"
      placeholder="Name"
      name="name"
      [(ngModel)]="item.text"
      (ngModelChange)="changedAnchor.emit(true)"
    >
  </div>
  <div class="col-auto">
    <i
      class="ri-delete-bin-fill text-danger"
      (click)="deleteAnchor()"
    ></i>
  </div>
</div>