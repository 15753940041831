import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {exhaust, exhaustMap, switchMap, take} from 'rxjs/operators';
import { HttpRequest } from '@angular/common/http';
import {ExerciseSetService} from './exercise-set.service';
import {ProfilmService} from '../shared/profilm.service';

@Injectable()
export class CustomerCompetenceResolver  {

  constructor(private exService: ExerciseSetService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const set = await this.exService.getExerciseSet(route.params.id);
    let result: any;
    set.inEnglish ? ProfilmService.language = 'en' : ProfilmService.language = 'de';
    if (set.acrossCompetencies && set.acrossCompetencies.length > route.params.dimensionid) {
      result = set.acrossCompetencies[route.params.dimensionid];
    }
    return result;
  }
}

