<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <span class="card-title">Übungssets</span>
          </div>
          <div class="col-auto d-none d-xl-flex">
            <div class="avatar">
              <div class="avatar-title rounded-circle bg-primary-dark">
                <i class="ri-tools-fill"></i>
              </div>
            </div>
          </div>
        </div>
        <h1>{{setcount}}</h1>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <span class="card-title">User</span>
          </div>
          <div class="col-auto  d-none d-xl-flex">
            <div class="avatar">
              <div class="avatar-title rounded-circle bg-primary-dark">
                <i class="ri-user-fill"></i>
              </div>
            </div>
          </div>
        </div>
        <h1>{{usercount}}</h1>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <span class="card-title">Kompetenzen</span>
          </div>
          <div class="col-auto  d-none d-xl-flex">
            <div class="avatar">
              <div class="avatar-title rounded-circle bg-primary-dark">
                <i class="ri-database-2-fill"></i>
              </div>
            </div>
          </div>
        </div>
        <h1 class="">{{dimensioncount}}</h1>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <span class="card-title">Anker</span>
          </div>
          <div class="col-auto  d-none d-xl-flex">
            <div class="avatar">
              <div class="avatar-title rounded-circle bg-primary-dark">
                <i class="ri-anchor-fill"></i>
              </div>
            </div>
          </div>
        </div>
        <h1 class="">{{ankercount}}</h1>
      </div>
    </div>
  </div>
</div>
<app-prm-catalog
  [coredimension]="coredimension"
  [exerciseTypes]="exerciseTypes"
></app-prm-catalog>