import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExerciseSetsSidebarService {

  public openedFolders: string[]

  constructor() {
    this.getFromLocalStorage();
  }

  public toggleFolder(folder: string) {
    if (this.openedFolders.includes(folder)) {
      this.openedFolders = this.openedFolders.filter(f => f !== folder)
    } else {
      this.openedFolders.push(folder)
    }
    this.saveToLocalStorage();
  }

  private saveToLocalStorage() {
    localStorage.setItem('openedFolders', JSON.stringify(this.openedFolders))
  }

  private getFromLocalStorage() {
    if (!localStorage.getItem('openedFolders')) {
      localStorage.setItem('openedFolders', JSON.stringify([]))
    };
    this.openedFolders = JSON.parse(localStorage.getItem('openedFolders'))
  }
}
