import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../auth/user.model';

@Pipe({
  name: 'initials'
})
export class UserInitialsPipe implements PipeTransform {
  transform(userName: string, ...args): any {
    let initials = '';
    const nameParts = userName.split(' ');
    if (nameParts.length > 1) {
      initials = nameParts[0].substr(0, 1).toUpperCase() + nameParts[nameParts.length - 1].substr(0, 1).toUpperCase();
    } else {
      initials = userName.substr(0, 2).toUpperCase();
    }
    return initials;
  }
}
