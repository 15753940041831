import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/confirm-modal/confirm-modal.component';
import { LoadingService } from 'src/app/shared/loading.service';
import { PrintService } from '../../print-layout/print.service';
import { LayoutService } from '../../shared/layout.service';
import { ExerciseSet } from '../exercise-set.model';
import { ExerciseSetProvider } from '../exercise-set.provider';
import { ExerciseSetService } from '../exercise-set.service';
import { Exercise } from '../exercise/exercise.model';

@Component({
  selector: 'app-sets-menu-operations',
  templateUrl: './sets-menu-operations.component.html',
  styleUrls: ['./sets-menu-operations.component.scss']
})
export class SetsMenuOperationsComponent {
  @Input() exerciseset: ExerciseSet;
  @Input() selectedexercise: Exercise;
  public isSticky: boolean = false;
  public saved = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 130;
  }

  constructor(
    public route: ActivatedRoute,
    private service: ExerciseSetService,
    public router: Router,
    public layout: LayoutService,
    private printService: PrintService,
    private exerciseSetProvider: ExerciseSetProvider,
    private modalService: BsModalService,
    private loadingService: LoadingService
  ) {
  }

  public openUebungssetModal() {
    const modalUebungssetRef = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog modal-centered',
      keyboard: false,
      initialState: {
        modalMsg: 'Möchtest du wirklich dieses <u>Übungsset löschen</u>?<br> Diese Aktion kann nicht rückgängig gemacht werden.'
      }
    });
    modalUebungssetRef.content?.hasConfirmed.subscribe((result) => {
      if (result) {
        this.onTrashBtnClick(this.exerciseset);
        modalUebungssetRef.hide();
      }
    });
  }

  public openKompetenzModal() {
    const modalKompetenzRef = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog modal-centered',
      keyboard: false,
      initialState: {
        modalMsg: 'Möchtest du wirklich diese <br><u>Vordefinierte Kompetenz löschen</u>?<br> Diese Aktion kann nicht rückgängig gemacht werden.'
      }
    });
    modalKompetenzRef.content?.hasConfirmed.subscribe((result) => {
      if (result) {
        this.onTrashAcrossCompetencie();
        modalKompetenzRef.hide();
      }
    });
  }

  public openUebungModal() {
    const modalUebungsRef = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog modal-centered',
      keyboard: false,
      initialState: {
        modalMsg: 'Möchtest du wirklich diese <u>Übung löschen</u>?<br> Diese Aktion kann nicht rückgängig gemacht werden.'
      }
    });
    modalUebungsRef.content?.hasConfirmed.subscribe((result) => {
      if (result) {
        this.onTrashBtnExerciseClick(this.selectedexercise);
        modalUebungsRef.hide();
      }
    });
  }

  onSaveBtnClick(e: ExerciseSet, $event) {
    this.saved = true;
    this.service.updateExerciseSet(e).subscribe(r => {
      setTimeout(() => {
        this.saved = false;
      }, 1000);
    });
  }

  async onCopyBtnClick(e: ExerciseSet) {
    const newSet = await this.service.copyExerciseSet(e);
    this.router.navigate(['/selectedset/' + newSet._id]);
    this.layout.exercisePanelOpen = false;
  }

  async onPrintBtnClick(e: ExerciseSet) {
    this.exerciseSetProvider.storage = e;
    this.loadingService.isLoading = true;
    await this.printService.exportExerciseSet(e);
    this.loadingService.isLoading = false;
  }

  onTrashBtnClick(e: ExerciseSet) {
    this.service.deleteExerciseSet(e).subscribe(r => {
      this.router.navigate(['/']);
      this.layout.exercisePanelOpen = false;
      this.service.deleteEmptyFolders();
    });
  }
  onTrashAcrossCompetencie() {
    const index = parseInt(this.route.snapshot.paramMap.get('dimensionid'), 10);
    this.exerciseset.acrossCompetencies.splice(index, 1);
    this.router.navigate(['/selectedset', this.exerciseset._id]);
    return;
  }
  onTrashBtnExerciseClick(selectedEx) {
    this.exerciseset.exercises.forEach((value, index) => {
      if (value === selectedEx) {
        this.exerciseset.exercises.splice(index, 1);
        this.router.navigate(['/selectedset', this.exerciseset._id]);
        return;
      }
    });
  }
}
