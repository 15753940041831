<cdk-accordion-item
  #accordionItem="cdkAccordionItem"
  role="button"
  tabindex="0"
  [ngClass]="{'li-active': accordionItem.expanded}"
  [attr.id]="'accordion-header-' + index"
  [attr.aria-expanded]="accordionItem.expanded"
  [attr.aria-controls]="'accordion-body-' + index"
  (opened)="opened.emit(index)"
  (closed)="opened.emit(null)"
>
  <div (click)="accordionItem.toggle()">
    <div class="row justify-content-center">
      <div class="col-auto">
        <ng-content></ng-content>
      </div>
      <div class="col text-primary text-decoration-underline">
        {{dimension.name}}
      </div>
      <div class="col-auto">
        <i
          class="ri-delete-bin-fill text-danger bg-white rounded-2 p-1"
          (click)="removeDimension(dimension)"
        ></i>
      </div>
    </div>
  </div>
  <div
    class="my-2"
    role="region"
    [style.display]="accordionItem.expanded ? '' : 'none'"
    [attr.id]="'accordion-body-' + index"
    [attr.aria-labelledby]="'accordion-header-' + index"
  >
    <ul class="nav nav-tabs border-0">
      <li
        class="nav-item"
        (click)="this.tabIndex = 0"
      >
        <a
          class="nav-link text-black border-0"
          [ngClass]="{
            'active': this.tabIndex == 0,
            'text-white': this.tabIndex != 0
            }"
        >Anker <div class="badge bg-dark rounded-pill">{{dimension.anchor.length}}</div></a>
      </li>
      <li
        class="nav-item"
        (click)="this.tabIndex = 1"
      >
        <a
          class="nav-link text-black border-0"
          [ngClass]="{
            'active': this.tabIndex == 1,
            'text-white': this.tabIndex != 1
            }"
        >Fragen <div class="badge bg-dark rounded-pill">{{dimension.questions.length}}</div></a>
      </li>
    </ul>
    <div
      *ngIf="tabIndex == 0"
      class="bg-white p-3"
      style="border-radius: 0.375rem; border-top-left-radius: 0"
    >
      <div class="mb-2">
        <ng-select
          (change)="addAnchor($event);"
          #anchorSelect
          [items]="anchorForSelection"
          bindLabel="text"
          bindValue="text"
          class="form-control-sm form-control"
        ></ng-select>
      </div>
      <ul
        class="list-group list-group-flush"
        *ngIf="this.dimension.anchor.length > 0; else noanchor;"
        cdkDropList
        (cdkDropListDropped)="reArrangeAnchor($event)"
      >
        <li
          cdkDrag
          class="list-group-item"
          *ngFor="let anchor of this.dimension.anchor; index as i "
        >
          <app-anchor-details
            [item]="anchor"
            [items]="dimension.anchor"
            (changedAnchor)="changedAnchor($event)"
          >
            <i
              class="ri-menu-line"
              cdkDragHandle
            ></i>
          </app-anchor-details>
        </li>
      </ul>
      <ng-template #noanchor>
        <div class="alert-info alert-sm alert">Es sind noch keine <b>Anker</b> ausgewählt.</div>
      </ng-template>
    </div>
    <div
      *ngIf="this.tabIndex == 1"
      class="bg-white p-3"
      style="border-radius: 0.375rem;"
    >
      <div class="mb-2">
        <ng-select
          *ngIf="this.service.hasExerciseInterviewType(selectedExercise); else noQtype"
          (change)="addQuestion($event);"
          #questionSelect
          [items]="selectableQuestions"
          bindLabel="text"
          bindValue="text"
          class="form-control-sm  form-control"
          ngModel
        ></ng-select>
        <ng-template #noQtype>
          <div class="alert alert-info">Als <b>Übungstyp</b> muss <b>ITW</b> gewält sein, sonst kannst du keine
            Fragen hinzufügen
          </div>
        </ng-template>
      </div>
      <ul
        class="list-group list-group-flush"
        *ngIf="this.dimension.questions.length > 0; else noquestion;"
        cdkDropList
        (cdkDropListDropped)="reArrangeQuestions($event)"
      >
        <li
          cdkDrag
          class="list-group-item"
          *ngFor="let question of this.dimension.questions; index as i "
        >
          <app-anchor-details
            [item]="question"
            [items]="dimension.questions"
          ><i
              class="ri-menu-line"
              cdkDragHandle
            ></i>
          </app-anchor-details>
        </li>
      </ul>
      <ng-template #noquestion>
        <div class="alert-info alert-sm alert">Es sind noch keine <b>Interviewfragen</b> ausgewählt.</div>
      </ng-template>
    </div>
  </div>
</cdk-accordion-item>