import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ExerciseSetService } from '../exercise-set.service';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { exhaust, exhaustMap, switchMap, take } from 'rxjs/operators';
import { HttpRequest } from '@angular/common/http';
import { ExerciseSet } from '../exercise-set.model';

@Injectable()
export class MatrixResolver  {

  constructor(private exService: ExerciseSetService) {

  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const setId = route.params.id;
    const set = await this.exService.getExerciseSet(setId);
    const matrixData = this.exService.getMatrixForSet(set);
    return matrixData;
  }
}
