import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorService } from '../shared/http-error.service';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  isLoginMode = true;
  errorMessage: string = null;
  password = '';

  constructor(private authService: AuthService, private router: Router, private httpErrorService: HttpErrorService) { }

  ngOnInit(): void {
    if (this.authService.user.getValue() != null) {
      this.router.navigate(['/']);
    }

    this.httpErrorService.getError().subscribe(errorResponse => {
      this.errorMessage = errorResponse.error.msg;
    });
  }

  onSubmit(form: NgForm) {
    this.errorMessage = null;
    if (!form.valid) {
      return;
    }
    const email = form.value.email.trim().toLowerCase();
    if (email.indexOf('@profil-m.de') > 0 || (email.indexOf("@reflact.com")) > 0) {
      window.location.href = "/api/sso/redirect/" + email
    } else {
      this.errorMessage = "Invalid Login";
    }
  }

  onSwitchMode() {
    this.errorMessage = null;
    this.isLoginMode = !this.isLoginMode;
  }
}