import { ProfilmAnchorModel } from './profilm-anchor.model';
import { ProfilmDimension } from './profilm-dimension.model';
import { ProfilmExerciseType } from './profilm-exercise-type.model';
import { ProfilmQuestionModel } from './profilm-question.model';
import { ProfilmService } from './profilm.service';

export class ProfilmSubDimension {
  public parentDimension?: ProfilmDimension;
  constructor(public de: string, public en: string, public anchor: ProfilmAnchorModel[], public questions: ProfilmQuestionModel[]) {
  }

  public get name() {
    return ProfilmService.isLanguageDe() ? this.de : this.en;
  }
  public addAnchor(anchor: ProfilmAnchorModel) {
    this.anchor.push(anchor);
  }

  public addQuestion(question: ProfilmQuestionModel) {
    this.questions.push(question);
  }

  public getAnkerOfTypes(types: ProfilmExerciseType[]): ProfilmAnchorModel[] {
    const result: ProfilmAnchorModel[] = this.anchor.filter((value, index) => {
      return value.hasExerciseTypes(types);
    });
    return result;
  }
  public getAllAnker() {
    return this.anchor;
  }

  public hasAnkerOfTypes(types: ProfilmExerciseType[]): boolean {
    for (let i = 0; i < this.anchor.length; i++) {
      if (this.anchor[i].hasExerciseTypes(types)) {
        return true;
      }
    }
    return false;
  }

}
