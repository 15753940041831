import { ProfilmService } from './profilm.service';

export class ProfilmExerciseType {
  constructor(public de: string, public fullde: string, public en: string, public fullen: string) {
  }
  public get name() {
    return ProfilmService.isLanguageDe() ? this.de : this.en;
  }
  public get fullname() {
    return ProfilmService.isLanguageDe() ? this.fullde : this.fullen;
  }
}


