<div class="row mt-3">
  <div class="col">
    <div class="card set-view-card catalog">
      <div class="card-body ">
        <h5>Profil M Kompetenz Katalog</h5>
        <ul class="list-group list-group-flush">
          <li
            *ngFor="let d of coredimension"
            class="list-group-item p-0 border-bottom-0"
          >
            <p
              class="cursor-pointer alert-dark alert"
              (click)="collapsed[d.name] = !collapsed[d.name]"
            >{{ d.name }}</p>
            <ul
              *ngFor="let s of d.subDimensions"
              class="list-group"
              [collapse]="!collapsed[d.name]"
              [isAnimated]="true"
            >
              <li class="list-group-item py-0 pe-0 border-0">
                <p
                  class="alert alert-secondary mb-1 cursor-pointer"
                  (click)="collapsed[s.name] = !collapsed[s.name]"
                > {{s.name}} </p>
                <ul
                  class="list-group list-group-flush"
                  [collapse]="!collapsed[s.name]"
                  [isAnimated]="true"
                >
                  <li
                    *ngFor="let a of s.anchor"
                    class="list-group-item"
                  >
                    <div class="float-end">
                      <span
                        class="badge rounded-pill me-2"
                        *ngFor="let et of exerciseTypes"
                        [ngClass]="{
                          'bg-warning' : a.hasExerciseTypes([et]),
                          'bg-light text-black' : !a.hasExerciseTypes([et]),
                          }"
                      >
                        {{et.name}}
                      </span>
                    </div>
                    {{a.text}}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>