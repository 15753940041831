<div class="login-dark">
  <form
    #authForm="ngForm"
    (ngSubmit)="onSubmit(authForm)"
  >
    <h2 class="visually-hidden">Login Form</h2>
    <div
      class="form-row"
      style="margin: 10px;"
    >
      <div
        class="col-auto text-center"
        style="padding: 5px;opacity: 1;filter: blur(0px);"
      >
        <div class="bg-white border rounded shadow">
          <img
            src="assets/ProfilM_Logo.png"
            width="100%"
            height="100%"
            style="padding: 10px;"
          />
        </div>
      </div>
    </div>

    <div
      *ngIf="errorMessage"
      class="alert alert-danger"
      role="alert"
    >
      {{errorMessage}}
    </div>

    <div class="form-group">
      <input
        type="email"
        id="email"
        class="form-control"
        ngModel
        name="email"
        required
        email
        placeholder="Email"
      />
    </div>


    <div class="form-group">
      <button
        class="btn btn-primary w-100 mb-3"
        type="submit"
        [disabled]="!authForm.valid"
      >
        Login
      </button>
    </div>


  </form>
</div>