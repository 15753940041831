import { Component } from '@angular/core';

@Component({
  selector: 'app-startpage-area',
  templateUrl: './startpage-area.component.html',
  styleUrls: ['./startpage-area.component.scss']
})
export class StartpageAreaComponent {

  public sidebarVisible = false;
}