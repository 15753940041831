import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseSet } from '../exercise-set.model';
import { ExerciseSetService } from '../exercise-set.service';

@Component({
  selector: 'app-exercise-set-matrix',
  templateUrl: './exercise-set-matrix.component.html',
  styleUrls: ['./exercise-set-matrix.component.scss']
})
export class ExerciseSetMatrixComponent {
  public matrixData: string[][];
  public exerciseset: ExerciseSet = new ExerciseSet('');

  constructor(private router: Router, private activeRoute: ActivatedRoute, public service: ExerciseSetService) {
    this.activeRoute.data.subscribe((value => {
      this.matrixData = value.matrixData;
      this.exerciseset = value.selectedExerciseSet;
    }));
  }

  isString(val): boolean {
    return typeof val === 'string';
  }
}