import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';

@Pipe({
  name: 'timediffFromDate'
})
export class TimediffFromDatePipe implements PipeTransform {

  transform(value: string): string {
    dayjs.extend(updateLocale);
    dayjs.extend(relativeTime);
    dayjs.updateLocale('de', {
      relativeTime: {
        past: '%s',
        s: 'vor wenigen Sekunden',
        ss: 'vor %d Sekunden',
        m: 'vor einer Minute',
        mm: 'vor %d Minuten',
        h: 'vor einer Stunde',
        hh: 'vor %d Stunden',
        d: 'vor einem Tag',
        dd: 'vor %d Tagen',
        M: 'vor einem Monat',
        MM: 'vor %d Monaten',
        y: 'vor einem Jahr',
        yy: 'vor %d Jahren'
      }
    });
    return dayjs(value).fromNow();
  }

}
