<ng-template #empty>
  <div
    style="margin-top: 5%;"
    class="alert alert-danger"
  >Das Übungsset konnte nicht gefunden werden!</div>
</ng-template>

<app-sets-menu-operations
  [exerciseset]="selectedExerciseSet"
  [selectedexercise]="selectedExercise"
></app-sets-menu-operations>
<div class="row">
  <div class="col">
    <div *ngIf="selectedExerciseSet; else empty">
      <div class="card set-view-card">
        <div class="card-header">
          <app-sets-menu
            [exerciseset]="selectedExerciseSet"
            label="Übersicht"
          ></app-sets-menu>
        </div>
        <div class="card-body">
          <app-sets-modiefied-label [exerciseset]="selectedExerciseSet"></app-sets-modiefied-label>
          <app-exercise-set-editor [exerciseset]="selectedExerciseSet"></app-exercise-set-editor>
          <hr class="my-4">
          <app-across-competencies
            [acrossCompetencies]="selectedExerciseSet.acrossCompetencies"
            [exerciseset]="selectedExerciseSet"
          >
          </app-across-competencies>
          <hr class="my-4">
          <app-exercise-list
            [exercises]="selectedExerciseSet.exercises"
            [exerciseset]="selectedExerciseSet"
          ></app-exercise-list>
        </div>
      </div>
    </div>
  </div>
  <div
    class="col-7 ps-0"
    *ngIf="layout.exercisePanelOpenBol"
  >
    <app-exercise-details
      *ngIf="!router.url.includes('custom')"
      [exerciseset]="selectedExerciseSet"
    ></app-exercise-details>

    <app-customer-dimension
      *ngIf="router.url.includes('custom')"
      [exerciseset]="selectedExerciseSet"
    ></app-customer-dimension>
    <br>
  </div>
</div>