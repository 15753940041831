import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public sideBarOpenBol = true;
  public exercisePanelOpenBol = false;
  private pageTitleStr = 'Kompetenzomat';
  public printView = false;

  constructor(router: Router) { }

  public set pageTitle(newval) {
    setTimeout(() => {
      this.pageTitleStr = newval;
    });
  }

  public get pageTitle() {
    return this.pageTitleStr;
  }

  /*** ***/
  public set sideBarOpen(newval) {
    setTimeout(() => {
      this.sideBarOpenBol = newval;
    });
  }

  public get sideBarOpen() {
    return this.sideBarOpenBol;
  }

  /*** ***/
  public get exercisePanelOpen() {
    return this.exercisePanelOpenBol;
  }

  public set exercisePanelOpen(newval) {
    setTimeout(() => {
      this.exercisePanelOpenBol = newval;
    });
  }
}
